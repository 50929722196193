var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading)?_c('div',{staticClass:"assign-groups"},[_c('div',{staticClass:"assign-groups__header"},[_c('span',{staticClass:"assign-groups__header__title"},[_vm._v(_vm._s(_vm.localization("app-assign-groups", "Assign Groups")))]),_c('i',{staticClass:"fa fa-times assign-groups__header__close",attrs:{"aria-hidden":"true"},on:{"click":_vm.hide_modal}})]),_c('div',{staticClass:"assign-groups__content__select"},[_c('img',{staticClass:"mr-10",attrs:{"src":require('@/assets/icons/form-search-icon.svg')}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.groupInput),expression:"groupInput"}],staticClass:"assign-groups__content__select__text",attrs:{"type":"text","placeholder":_vm.localization('app-search-group-name', 'Search Group Name')},domProps:{"value":(_vm.groupInput)},on:{"input":function($event){if($event.target.composing){ return; }_vm.groupInput=$event.target.value}}})]),_c('div',{staticClass:"assign-groups__content has-slimscroll"},[_c('div',{staticClass:"assign-groups__content__heading"},[_c('div',{staticClass:"ml-50 mr-auto"},[_vm._v(" "+_vm._s(_vm.localization("app-groups", "Groups"))+" ")]),_c('div',[_vm._v(_vm._s(_vm.localization("app-read", "Read")))]),_c('div',[_vm._v(_vm._s(_vm.localization("app-write", "Write")))])]),_c('div',{staticClass:"assign-groups__content__body has-slimscroll"},_vm._l((_vm.filteredAllGroups),function(group,i){return _c('div',{key:i,staticClass:"assign-groups__content__body__row"},[_c('div',{staticClass:"assign-groups__content__body__row__main"},[(group.properties && group.properties.icon)?_c('div',{staticClass:"image is-24x24 mr-10"},[_c('img',{staticClass:"image is-24x24 is-rounded",attrs:{"src":group.properties.icon}})]):_c('div',{staticClass:"assign-groups__content__body__row__main__icon",style:(("background-color: " + (group.properties && group.properties.color
                  ? group.properties.color
                  : _vm.hashed_color(group.name, 'projectManagement'))))},[_vm._v(" "+_vm._s(group.name.charAt(0).toUpperCase())+" ")]),_c('span',{staticClass:"assign-groups__content__body__row__main__name"},[_vm._v(_vm._s(group.name))])]),_c('span',{staticClass:"sh-access-tag",class:{
            'sh-access-tag--read':
              _vm.selectedGroups[group.uid] &&
              (_vm.selectedGroups[group.uid].access === 'read' ||
                _vm.selectedGroups[group.uid].access === 'write')
          },staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.select_group(group.uid, 'read')}}},[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}})]),_c('span',{staticClass:"sh-access-tag",class:{
            'sh-access-tag--write':
              _vm.selectedGroups[group.uid] &&
              _vm.selectedGroups[group.uid].access === 'write'
          },staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.select_group(group.uid, 'write')}}},[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}})])])}),0)]),_c('div',{staticClass:"add-item__footer"},[(_vm.is_saving)?_c('div',{staticClass:"add-item__footer__button button is-pulled-right"},[_c('i',{staticClass:"fa fa-spinner fa-spin",attrs:{"aria-hidden":"true"}})]):_c('div',{staticClass:"add-item__footer__button button has-text-white is-pulled-right is-medium-16-500-19",on:{"click":_vm.add_groups}},[_vm._v(" "+_vm._s(_vm.localization("app-save", "Save"))+" ")]),_c('div',{staticClass:"add-item__footer__button__cancel button is-pulled-right is-medium-16-500-19 mr-10",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.localization("app-cancel", "Cancel"))+" ")])])]):_c('modal-skeleton')}
var staticRenderFns = []

export { render, staticRenderFns }