var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"columns is-flex align-center is-gapless"},[_c('div',{staticClass:"column ",class:[_vm.$route.name.includes('asset-details') ? 'is-3' : 'is-4']},[_c('router-link',{attrs:{"to":{ name: 'terra-viewer', params: { id: _vm.item.uid } }}},[_c('main-details-media',{attrs:{"truncate_value":_vm.$route.name.includes('asset-details') ? 20 : 36,"hideImage":_vm.$route.name == 'asset-list' ? false : true,"show_tooltip":true,"info":{
            name: _vm.item.name,
            name_class:
              'is-semiBold-16-600-19 has-text-theme-black name-highlights-on-hover',
            subtext: _vm.item.description
              ? _vm.item.description
              : _vm.localization(
                  'app-no-description-found',
                  '*No description found'
                ),
            subtext_class:
              ' is-regular-14-500-17 has-text-theme-grey has-opacity-7',
            type: 'projectManagement'
          }}})],1)],1),_c('div',{staticClass:"column ",class:[_vm.$route.name.includes('asset-details') ? 'is-3' : 'is-2_5']},[_c('div',{staticClass:"is-flex is-vcenter"},[_c('user-avatar',{attrs:{"user":_vm.item.owner,"pxsize":30,"custom_class":'tiny-user-avatar',"show_tooltip":true}}),(_vm.item.owner)?_c('p',{directives:[{name:"username",rawName:"v-username:[22].truncate",value:(_vm.item.owner),expression:"item.owner",arg:22,modifiers:{"truncate":true}}],staticClass:"ml-5 is-medium-14-500-17 has-text-weight-semibold has-text-lightgrey is-capitalized"}):_vm._e()],1)]),_c('div',{staticClass:"column ",class:[_vm.$route.name.includes('asset-details') ? 'is-2' : 'is-2_5']},[_c('user-tag',{attrs:{"custom_size":30,"custom_class":'tiny-user-avatar',"users":_vm.item.readUsers.concat( _vm.item.writeUsers).map(
            function (user) { return _vm.$store.getters.state_users[user.uid]; }
          ),"tagsCountToShow":_vm.$route.name.includes('asset-details') ? 2 : 3}})],1),_c('div',{staticClass:"column ",class:[_vm.$route.name.includes('asset-details') ? 'is-3' : 'is-2']},[_c('div',{staticClass:"is-medium-14-500-17 no-whitespace-wrap"},[_vm._v(" "+_vm._s(_vm._f("prettyDate")(_vm.item.updated))+" ")])]),_c('div',{staticClass:"column is-1 sh-list__item--icon is-flex"},[_c('div',{staticClass:"is-pointer mr-10",on:{"click":function($event){return _vm.$emit('edit', _vm.item)}}},[_c('sh-icon',{staticClass:"is-16x16",attrs:{"name":'pen'}})],1),_c('div',{staticClass:"is-pointer",on:{"click":function($event){_vm.showDeleteModal = true;
          _vm.currentTerra = _vm.item;}}},[_c('sh-icon',{staticClass:"is-16x16",attrs:{"name":'trash'}})],1),_c('router-link',{staticClass:"is-pointer ml-30",attrs:{"to":{ name: 'terra-details', params: { id: _vm.item.uid } }}},[_c('sh-icon',{staticClass:"is-15x15",attrs:{"name":'group-right-arrow'}})],1)],1)]),(_vm.showDeleteModal)?_c('delete-modal',{attrs:{"resourceId":_vm.item.uid,"resourceName":_vm.item.name,"resourceType":'terra',"resourceService":_vm.item.isContainer ? _vm.containerService : _vm.terraService},on:{"closed":function($event){_vm.showDeleteModal = false},"deleted":function($event){return _vm.$emit('deleted-item')}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }