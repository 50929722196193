<template>
  <div class="choose-apps card">
    <div class="card-header padding-10" style="box-shadow: none">
      <div
        class="card-header-title is-flex is-between align-center is-capitalized is-medium-18-500-22"
      >
        {{ asset.name }}
        <i
          @click="$emit('close')"
          class="fa fa-times is-pulled-right mt-5 is-pointer"
          aria-hidden="true"
        ></i>
      </div>
    </div>
    <div class="card-content mx-10">
      <div class="choose-apps--lists">
        <div class="card" v-if="therm.length">
          <header class="card-header py-5">
            <a class="card-header-icon" @click="therm_active = !therm_active">
              <sh-icon
                class="is-20x20"
                :name="therm_active ? 'expand-active' : 'collapse-active'"
              ></sh-icon>
            </a>
            <p
              class="card-header-title no-padding-left is-capitalized is-medium-18-500-22"
            >
              {{ localization("app-thermography", "thermography") }} ({{
                filtered_therm.length
              }})
            </p>
            <div
              @click.stop="
                $router.push({
                  name: 'therm-view-list',
                  query: { asset: asset.uid, name: asset.name }
                })
              "
              class=" is-pointer is-pulled-right is-flex align-center pr-10 is-medium-14-500-17"
            >
              <sh-icon
                :name="'therm'"
                file="helper_sprite"
                class="is-18x18 mr-5"
              ></sh-icon
              >{{ localization("app-therm", "Therm") }}
            </div>
          </header>
          <div
            class="card-content no-padding-top no-margin-bottom mt-5"
            v-if="therm_active"
          >
            <searchbar @search="search_therm = $event"></searchbar>
            <hr />
            <div
              class=" columns is-regular-12-00-14 has-text-grey pt-5 is-marginless"
            >
              <div class="column is-5">
                {{ localization("app-therm", "Therm") }}
              </div>
              <div class="column is-2 ">
                {{ localization("app-therm-affected", "Therm Affected") }}
              </div>
              <div class="column is-2 ">
                {{ localization("app-total-dc-capacity", "Total DC Capacity") }}
              </div>
              <div class="column is-2 ">
                {{ localization("app-issues", "Issues") }}
              </div>
            </div>
            <div
              class="choose-apps--therm has-slimscroll-xs"
              v-if="filtered_therm.length"
            >
              <div
                v-for="item in filtered_therm"
                :key="item.uid"
                class="sh-list__item"
              >
                <div
                  class="columns choose-apps--therm--item is-marginless list-card is-flex align-center"
                  v-if="item"
                >
                  <div class="column is-5">
                    <router-link
                      :to="{ name: 'therm-viewer', params: { id: item.uid } }"
                      @click.native="$emit('close')"
                    >
                      <info-block
                        :hideImage="true"
                        :info="{
                          name: localization(
                            `app-${item.name
                              .toLowerCase()
                              .split(' ')
                              .join('-')}`,
                            item.name
                          ),
                          name_class:
                            'is-semiBold-16-600-22 name-highlights-on-hover',
                          bottom: localization(
                            'app-thermal-scanning',
                            'Thermal Scanning'
                          ),
                          bottom_class:
                            ' is-regular-14-500-17 has-text-theme-grey has-opacity-7',
                          type: 'projectManagement'
                        }"
                        :truncate_value="40"
                      />
                    </router-link>
                    <router-link
                      v-if="$store.getters.therm_v2"
                      :to="{ name: 'therm-v2', params: { id: item.uid } }"
                      class="tag is-info"
                      >v2.0</router-link
                    >
                  </div>
                  <div class="column is-2">
                    <h1
                      class="is-medium-16-500-19 has-text-theme-black mt-5 affected"
                    >
                      {{
                        item.uid === "jxoieYzpFA"
                          ? 0
                          : (
                              ((item.metrics.modules.affected || 0) *
                                (item.moduleWattPeak || 300)) /
                              1000
                            ).toFixed(1)
                      }}KW
                    </h1>
                  </div>
                  <div class="column is-2">
                    <h1
                      class="is-medium-16-500-19 has-text-theme-black mt-5 capacity"
                    >
                      {{
                        item.uid === "jxoieYzpFA"
                          ? 0
                          : (
                              (item.metrics.modules.total || 0) *
                              ((item.moduleWattPeak || 300) / 1000000)
                            ).toFixed(1)
                      }}MW
                    </h1>
                  </div>
                  <div class="column is-2">
                    <h1 class="is-medium-16-500-19 has-text-theme-black mt-5">
                      {{
                        (item.metrics.issues &&
                          total_issues(item.metrics.issues)) ||
                          "0"
                      }}
                    </h1>
                  </div>
                  <div class="column is-1 is-flex is-end actions">
                    <router-link
                      :to="{
                        name: 'therm-view-details',
                        params: { id: item.uid }
                      }"
                      class="is-pointer pr-10"
                    >
                      <div class="is-flex align-center is-medium-14-500-17">
                        {{ localization("app-dashboard", "Dashboard") }}
                        <sh-icon
                          :name="'group-right-arrow'"
                          class=" ml-5 is-15x15"
                        ></sh-icon>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="m-no-terra">
              <div class="m-no-terra--img">
                <img
                  src="@/assets/illustrations/m-no-thermal-illustration.svg"
                  alt=""
                />
              </div>
              <div
                class="is-flex is-center mt-20 is-medium-16-500-19 has-primary-text"
              >
                {{ localization("app-no-thermal", "No Thermal") }}
              </div>
              <div
                class="is-flex is-center mt-10 is-regular-12-00-18 has-primary-text has-opacity-8"
              >
                {{ localization("app-no-result-found", "No result found") }}
              </div>
            </div>
          </div>
        </div>
        <div class="card mt-20" v-if="terra.length">
          <header class="card-header py-5">
            <a class="card-header-icon" @click="terra_active = !terra_active">
              <sh-icon
                class="is-20x20"
                :name="terra_active ? 'expand-active' : 'collapse-active'"
              ></sh-icon>
            </a>
            <p
              class="card-header-title no-padding-left is-capitalized is-medium-18-500-22"
            >
              {{ localization("app-terra", "Terra") }} ({{
                filtered_terra.length
              }})
            </p>
            <div
              @click.stop="
                $router.push({
                  name: 'terra-list',
                  query: { asset: asset.uid, name: asset.name }
                })
              "
              class="is-pointer is-pulled-right is-flex align-center pr-10 is-medium-14-500-17"
            >
              <sh-icon
                :name="'terra'"
                file="helper_sprite"
                class="is-18x18 mr-5"
              ></sh-icon
              >{{ localization("app-terra", "Terra") }}
            </div>
          </header>
          <div class="card-content no-padding-top mt-5" v-if="terra_active">
            <searchbar @search="search_terra = $event"></searchbar>
            <hr />
            <div
              class="mt-5 choose-apps--terra has-slimscroll-xs"
              v-if="filtered_terra.length"
            >
              <div
                class="columns choose-apps--terra--item is-marginless list-card  py-5 is-flex align-center sh-list__item"
                v-for="item in filtered_terra"
                :key="item.uid"
              >
                <div class="column is-11">
                  <router-link
                    :to="{ name: 'terra-viewer', params: { id: item.uid } }"
                    @click.native="$emit('close')"
                  >
                    <info-block
                      :info="{
                        name: item.name,
                        name_class:
                          'is-semiBold-16-600-22 name-highlights-on-hover',
                        bottom: item.description
                          ? item.description
                          : localization(
                              'app-no-description-found',
                              '*No description found'
                            ),
                        bottom_class:
                          'is-regular-14-500-17 has-opacity-7 has-text-theme-black',
                        type: 'projectManagement'
                      }"
                      :truncate_value="35"
                      :hideImage="true"
                    ></info-block>
                  </router-link>
                </div>
                <div class="column is-flex is-end is-1 actions">
                  <router-link
                    :to="{ name: 'terra-details', params: { id: item.uid } }"
                    class="is-pointer pr-10"
                  >
                    <div class="is-flex align-center is-medium-14-500-17">
                      {{ localization("app-dashboard", "Dashboard") }}
                      <sh-icon
                        :name="'group-right-arrow'"
                        class=" ml-5 is-15x15"
                      ></sh-icon>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
            <div v-else class="m-no-terra">
              <div class="m-no-terra--img">
                <img src="@/assets/illustrations/terra-illustration.svg" alt />
              </div>
              <div
                class="is-flex is-center mt-20 is-medium-16-500-19 has-primary-text"
              >
                {{ localization("app-no-terra", "No Terra") }}
              </div>
              <div
                class="is-flex is-center mt-10 is-regular-12-00-18 has-primary-text has-opacity-8"
              >
                {{ localization("app-no-result-found", "No result found") }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-content mt-30">
        <p class="is-medium-14-500-17 mb-15">
          {{ localization("app-applications", "Applications") }}
        </p>
        <div class="is-flex align-center" style="flex-wrap:wrap">
          <div v-for="item in apps" :key="item.id">
            <div
              class="choose-apps--chip is-pointer px-10 py-5 mr-10 is-inline-flex align-center"
              v-if="item.split_permissions"
              @click="
                item.id == 7 ? Strategix4() : (goto_app(item), $emit('close'))
              "
            >
              <span>
                <sh-icon
                  :name="item.sprite"
                  file="helper_sprite"
                  class="is-18x18"
                ></sh-icon>
              </span>
              <p class="is-medium-14-500-17 ml-5">
                {{ localization(`app-${item.name.toLowerCase()}`, item.name) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import searchbar from "@/app/old/desktop/core/shared/components/searchbar";
  import InfoBlock from "@/app/old/desktop/shared/components/info-block";
  import { apiService } from "@/app/old/desktop/shared/services/";

  export default {
    props: ["terra", "therm", "asset"],
    components: {
      searchbar,
      InfoBlock
    },
    data() {
      return {
        terra_active: true,
        therm_active: true,
        search_terra: "",
        search_therm: "",
        apps: [
          // {
          //   id: 1,
          //   name: "Tickets",
          //   to: "tickets",
          //   sprite: "tickets",
          //   permissions_key: "tickets",
          //   split_permissions: true
          // },
          // {
          //   id: 2,
          //   name: "Vault",
          //   to: "vault",
          //   sprite: "vault",
          //   permissions_key: "vault",
          //   split_permissions: true
          // },
          {
            id: 3,
            name: "Missions",
            to: "mission-list",
            sprite: "missions",
            permissions_key: "missions",
            split_permissions: true
          },
          {
            id: 4,
            name: "Processing",
            to: "process-list",
            sprite: "processing",
            permissions_key: "processing",
            split_permissions: true
          },
          // {
          //   id: 5,
          //   name: "Forms",
          //   to: "forms",
          //   sprite: "forms",
          //   permissions_key: true,
          //   split_permissions: this.$store.getters.forms
          // },
          {
            id: 6,
            name: "System Model",
            to: "sm-template",
            sprite: "vault",
            permissions_key: true,
            split_permissions: this.$store.getters.system_model
          },
          {
            id: 7,
            name: "Monitor",
            to: "mission-list",
            sprite: "missions",
            permissions_key: "missions",
            split_permissions: this.$store.getters.strategix4
          }
        ]
      };
    },
    methods: {
      total_issues(issues) {
        return _.sum(Object.values({ ...issues, table: 0 }));
      },
      async Strategix4() {
        let body = {
          email:
            localStorage.getItem("strategix4_email") || "vijaym@sensehawk.com",
          password:
            localStorage.getItem("strategix4_password") || "Sensehawk@123"
        };

        let response = await apiService.get_strategix4_token({
          body
        });

        let token = response?.data?.token;
        let env = process.env.NODE_ENV == "production" ? "PROD" : "STAGE";

        token =
          token ||
          localStorage.getItem("strategix4_token") ||
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjdXN0b21lck51bWJlciI6Ijk5OTA1NjMiLCJlbWFpbCI6InZpamF5bUBzZW5zZWhhd2suY29tIiwiZXhwIjoxNjI1MDQ3MjkxLCJyb2xlIjoiIiwiYXBpX3ZlcnNpb24iOiIwLjAuMSIsImlhdCI6MTYyNDk2MDg5MX0.u_gDznzypbbN9l2KOGynOVlhiuy3vUw4U3E3K4DVxFk";
        let url = `https://sensehawk.strategix4.com/#index?token=${token}&projectid=${env}-${this.asset.uid}`;
        window.open(url, "_blank").focus();
        this.$emit("close");
      },
      goto_app(app) {
        if (
          app.permissions_key === true ||
          this.has_app_access(app.permissions_key)
        )
          this.$router.push(
            app.to == "sm-template"
              ? {
                  name: app.to,
                  params: { asset: this.asset.uid }
                }
              : {
                  name: app.to,
                  query: { asset: this.asset.uid }
                }
          );
        else this.no_access_popup(app.permissions_key);
      }
    },
    watch: {
      $route(val) {
        this.$emit("close");
      }
    },
    computed: {
      filtered_terra() {
        return this.terra.filter(item =>
          item.name.toLowerCase().includes(this.search_terra.toLowerCase())
        );
      },
      filtered_therm() {
        return this.therm.filter(item =>
          item.name.toLowerCase().includes(this.search_therm.toLowerCase())
        );
      }
    }
  };
</script>

<style lang="scss">
  .choose-card {
    border-radius: 0.8rem;
    margin-top: 3rem;
  }
  .choose-apps {
    height: 100%;
    border-radius: 1rem;
    .searchbar .searchbar__input {
      box-shadow: none;
    }
    &--lists {
      min-height: 30rem;
      .m-no-terra {
        height: 28rem;
      }
    }
    &--therm,
    &--terra {
      height: 20rem;
      .actions {
        visibility: hidden;
      }
      overflow: auto;
      &--item:hover {
        background: #e8f3fb;
        .info-block__info--name-block .name,
        .card-header-title {
          color: $primary;
        }
        .actions {
          visibility: visible;
        }
      }
    }
    hr {
      margin: 0.5rem 0;
      height: 1px;
    }
    .sh-list {
      &__item {
        padding: 0;
      }
    }
    .card {
      border-radius: 0.5rem;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
      border: solid 1.5px rgba(184, 187, 200, 0.5);
    }
    &--chip {
      border-radius: 15px;
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
      border: solid 1px rgba(115, 119, 144, 0.2);
      height: 3rem;
    }
  }
</style>
