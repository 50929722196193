var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('button',{staticClass:"upload_button mb-20",class:{
      'is-not-allowed disabled-link': !_vm.access('core', 'can_modify_report')
    },on:{"click":function($event){_vm.access('core', 'can_modify_report')
        ? _vm.open_popup()
        : _vm.no_access_popup('core', 'can_modify_report')}}},[_c('svg',{staticClass:"image is-24x24 mr-5",attrs:{"viewBox":"0 0 100 100"}},[_c('use',{attrs:{"xlink:href":require("@/assets/icons/helper_sprite.svg") + "#upload-48px"}})]),_c('span',{staticClass:"is-regular-14-500-17 has-opacity-8 has-text-theme-black"},[_vm._v(_vm._s(_vm.localization("app-upload-more", "Upload more")))])]),_c('b-modal',{attrs:{"active":_vm.popup_active,"width":640,"scroll":"clip"}},[_c('div',{staticClass:"card has-slimscroll-xs",staticStyle:{"max-height":"100vh","overflow":"scroll"}},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-header-title sh-text-normal-18 padding-20"},[_vm._v(" "+_vm._s(_vm.localization("app-upload-report", "Upload Report"))+" ")]),_c('a',{staticClass:"card-header-icon",attrs:{"aria-label":"more options"},on:{"click":function($event){_vm.popup_active = false}}},[_c('span',{staticClass:"icon mr-10"},[_c('i',{staticClass:"fas fa-times",attrs:{"aria-hidden":"true"}})])])]),_c('div',{staticClass:"card-content"},[_c('report-form',{attrs:{"report_details":_vm.report_details,"services":_vm.services,"report_types":_vm.report_types,"form_for":'reports_data'},on:{"onchange_filetype":function($event){_vm.set_allow_types();
            _vm.uppy.reset();},"input":function($event){_vm.uppy1 = false;
            _vm.form = $event;
            _vm.open_popup();}}}),_c('div',{staticStyle:{"height":"250px","padding":"0 3rem"}},[(_vm.uppy1)?_c('div',{staticClass:"DashboardContainer",attrs:{"id":"ReportUploadDashboard"}}):_vm._e()])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }