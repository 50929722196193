var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-card"},[_c('router-view'),_c('div',{staticClass:"list-top-section",class:{ 'px-20': _vm.$route.name.includes('list') }},[_c('div',{staticClass:"list-top-section--search"},[_c('searchbar',{on:{"search":function($event){return _vm.pageChangeHandle(1, $event)}}})],1),_c('div',{staticClass:"list-top-section--create"},[(_vm.access('core', 'create_teams'))?_c('a',{on:{"click":function($event){return _vm.open_form(_vm.RoleFormComponent, { header: 'Create Role for' })}}},[_c('div',{staticClass:"sh-button sh-button--create sh-button--inverted is-semiBold-14-600-17"},[_vm._v(" "+_vm._s(_vm.localization("app-new-role", "New Role"))+" ")])]):_c('a',{on:{"click":function($event){return _vm.no_access_popup('core', 'create_teams')}}},[_c('div',{staticClass:"sh-button sh-button--create sh-button--inverted is-semiBold-14-600-17 disabled-link"},[_vm._v(" "+_vm._s(_vm.localization("app-new-role", "New Role"))+" ")])])])]),(_vm.data && _vm.data.length)?_c('div',[_c('list-header'),_vm._l((_vm.data),function(role){return _c('div',{key:role.uid,staticClass:"sh-list"},[_c('div',{staticClass:"sh-list__item columns"},[_c('div',{staticClass:"column "},[_c('router-link',{staticClass:"has-text-dark",attrs:{"to":{ name: 'role-details', params: { id: role.uid } }}},[_c('main-details-media',{attrs:{"info":{
                name: role.name,
                name_class: 'is-semiBold-16-600-19 name-highlights-on-hover',
                properties: role.properties,
                type: 'userManagement'
              }}})],1)],1),_c('div',{staticClass:"column "},[(
              role.created_by &&
                _vm.$store.getters.state_users[role.created_by.uid] &&
                _vm.$store.getters.state_users[role.created_by.uid].first_name
            )?_c('main-details-media',{attrs:{"info":{
              name: ((_vm.$store.getters.state_users[role.created_by.uid].first_name) + " " + (_vm.$store.getters.state_users[role.created_by.uid].last_name)),
              name_class: 'is-medium-16-500-19 has-primary-text',
              image:
                _vm.$store.getters.state_users[role.created_by.uid]
                  .display_picture,
              type: 'userManagement'
            }}}):(role.created_by && role.created_by.uid)?_c('main-details-media',{attrs:{"unknown":true,"info":{
              name: role.created_by.email.includes('@sensehawk')
                ? 'Sensehawk'
                : 'Unknown',
              type: 'userManagement'
            }}}):_vm._e()],1),_c('div',{staticClass:"column"},[_c('app-tag',{attrs:{"apps":role.applications,"howManyToShow":2}})],1),_c('div',{staticClass:"column"},[_c('user-tag',{attrs:{"no_access":_vm.no_access,"users":role.users.map(function (user) { return _vm.$store.getters.state_users[user.uid]; }),"tagsCountToShow":_vm.user_count || 3,"custom_class":'tiny-user-avatar is-medium-14-500-17',"custom_size":30}})],1),_c('div',{staticClass:"column is-flex is-center"},[_c('status-tag',{attrs:{"status":'active'}})],1)])])})],2):_c('div',[(_vm.isLoading)?_c('div',[_c('list-header'),_c('list-skeleton')],1):_c('no-results',[_c('img',{attrs:{"slot":"image","src":require("@/assets/illustrations/roles-illustration.svg"),"alt":""},slot:"image"}),_c('div',{attrs:{"slot":"button"},slot:"button"},[_c('a',{on:{"click":function($event){return _vm.open_form(_vm.RoleFormComponent, { header: 'Create Role for' })}}},[_c('div',{staticClass:"sh-button sh-button--create"},[_vm._v(" "+_vm._s(_vm.localization("app-create-new-role", "Create New Role"))+" ")])])])])],1),_c('div',[_c('pagination',{staticClass:"articles-list__pagination",attrs:{"default_page_size":_vm.default_page_size,"current-page":_vm.currentPage,"page-count":_vm.pageCount,"visiblePagesCount":_vm.visiblePagesCount,"totalRecords":_vm.totalRecords},on:{"nextPage":function($event){return _vm.pageChangeHandle('next')},"previousPage":function($event){return _vm.pageChangeHandle('previous')},"loadPage":_vm.pageChangeHandle}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }