<template>
  <div class="sensehawk-app__login">
    <div id="okta-signin-container"></div>
    <footer class="is-flex align-center is-center pb-20">
      <a
        class="has-text-blue is-medium-12-500-14"
        href="https://sensehawk.com/privacy-policy-for-application/"
        target="_blank"
      >
        Privacy Policy
      </a>
    </footer>
  </div>
</template>

<script>
  // import OktaSignIn from "@okta/okta-signin-widget";
  // import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";

  import sampleConfig from "./config";

  export default {
    async created() {
      let token = window.$cookies.get("access_token");
      if (token) this.$router.replace({ name: "home" });
    },
    name: "Login",
    mounted: function() {
      let scopes = sampleConfig.oidc.scopes.join(" ");
      let state = Math.random().toString(36).substring(5);

      if (window.$cookies.get("access_token")) return;
      this.$nextTick(function() {
        this.widget = new OktaSignIn({
          /**
           * Note: when using the Sign-In Widget for an OIDC flow, it still
           * needs to be configured with the base URL for your Okta Org. Here
           * we derive it from the given issuer for convenience.
           */
          baseUrl: sampleConfig.oidc.issuer.split("/oauth2")[0],
          clientId: sampleConfig.oidc.clientId,
          redirectUri: sampleConfig.oidc.redirectUri,
          logo: require("@/assets/logos/sensehawk-logo-small.svg"),
          i18n: {
            en: {
              "primaryauth.title": "Sign in to sensehawk  "
            }
          },
          features: {
            showPasswordToggleOnSignInPage: true,
            idpDiscovery: true
          },
          idpDiscovery: {
            requestContext: `${sampleConfig.oidc.issuer}/v1/authorize?client_id=${sampleConfig.oidc.clientId}&response_type=code&scope=${scopes}&redirect_uri=${sampleConfig.oidc.redirectUri}&state=${state}`
          },
          authParams: {
            responseType: "code",
            pkce: false,
            issuer: sampleConfig.oidc.issuer,
            display: "page",
            scopes: sampleConfig.oidc.scopes
          }
        });

        this.widget.renderEl(
          { el: "#okta-signin-container" },
          () => {
            /**
             * In this flow, the success handler will not be called because we redirect
             * to the Okta org for the authentication workflow.
             */
          },
          err => {
            throw err;
          }
        );
      });
    },
    destroyed() {
      // Remove the widget from the DOM on path change
      if (this.widget) this.widget.remove();
    }
  };
</script>
<style lang="scss">
  .sensehawk-app__login {
    min-height: 100vh;
    background-color: $primary-background;
    #okta-sign-in {
      overflow: visible;
      top: 80px;
    }
    #okta-sign-in.auth-container .button-primary {
      background-color: $primary;
      background: $primary;
      border-color: $primary;
      &:hover {
        background-color: $primary;
        background: $primary;
        border-color: $primary;
      }
    }

    footer {
      margin-top: 12.5rem;
      a {
        font-family: montserrat, Arial, Helvetica, sans-serif !important;
        &:hover {
          color: $primary;
        }
      }
    }
  }
</style>
