var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home-skeleton"},[_c('skeleton-loading',_vm._l((2),function(i){return _c('row',{key:i,attrs:{"gutter":{ top: '10px', bottom: '10px' }}},[_c('column',{staticClass:"home-skeleton-card",attrs:{"span":24}},[_c('square-skeleton',{attrs:{"boxProperties":{
            bottom: '15px',
            height: '160px'
          }}}),_c('div',{staticClass:"px-20"},[_c('square-skeleton',{attrs:{"boxProperties":{
              bottom: '15px',
              height: '20px'
            }}}),_c('square-skeleton',{attrs:{"boxProperties":{
              bottom: '15px',
              height: '20px',
              width: '30%'
            }}})],1)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }