<template>
  <div>
    <div v-if="details" class="columns">
      <transfer-owner
        ref="containerTransferOwner"
        :name="details.name"
        :service="containerService"
        :id="details.uid"
        @update_user="update_owner(details, $event)"
      ></transfer-owner>
      <div class="column is-3">
        <details-skeleton v-if="isloading"></details-skeleton>
        <details-sidecard v-else>
          <template slot="header">
            <main-details-media
              :truncate_value="20"
              :info="{
                name: details.name,
                properties: details.properties,
                type: 'projectManagement',
                name_class: 'is-semiBold-16-600-22'
              }"
            ></main-details-media>
            <div
              class="sh-dropdown is-pointer"
              v-click-outside="() => (dropdown ? (dropdown = !dropdown) : null)"
            >
              <div @click="dropdown = !dropdown">
                <sh-icon :name="'3-dots-menu'" class="is-20x20"></sh-icon>
              </div>
              <div
                class="sh-dropdown-content is-paddingless"
                v-if="dropdown == true"
              >
                <p
                  class="sh-dropdown-content__item is-medium-14-500-17"
                  @click="open_form_popup(details)"
                >
                  <span class="mr-10">
                    <sh-icon :name="'edit-small'" class="is-24x24"></sh-icon>
                  </span>
                  <span class="no-whitespace-wrap"
                    >{{ localization("app-edit-container", "Edit Container") }}
                  </span>
                </p>
                <p
                  @click="$refs.containerTransferOwner.open_popup()"
                  class="sh-dropdown-content__item is-medium-14-500-17 is-flex is-vcenter"
                >
                  <span>
                    <sh-icon :name="'transfer'" class="is-18x18"></sh-icon>
                  </span>
                  <span class="no-whitespace-wrap ml-10">
                    {{
                      localization(
                        "app-transfer-ownership",
                        "Transfer ownership"
                      )
                    }}
                  </span>
                </p>

                <p
                  @click="showDeleteModal = true"
                  class="sh-dropdown-content__item"
                >
                  <span>
                    <sh-icon :name="'delete-small'" class="is-22x22"></sh-icon>
                  </span>
                  <span class="no-whitespace-wrap is-medium-14-500-17 ml-10">{{
                    localization("app-delete-container", "Delete Container")
                  }}</span>
                </p>
              </div>
            </div>
          </template>

          <template slot="main">
            <div class="details-card__content">
              <div class="details-card__content-heading">
                {{ localization("app-description", "Description") }}
              </div>
              <div class="is-flex">
                <p
                  class="details-card__content-description cursor-is-text"
                  v-if="update == false"
                  @click="update = true"
                >
                  {{
                    details.description
                      ? details.description
                      : localization(
                          "app-no-description-found",
                          "*No description found"
                        )
                  }}
                </p>
                <input
                  type="text"
                  class="details-card__content-description bpm-less"
                  style="12px"
                  :placeholder="
                    localization('app-add-description', 'Add description')
                  "
                  v-else
                  v-focus
                  @keypress.enter="update_description"
                  v-model="updated_description"
                  v-click-outside="() => (update = false)"
                />
                <p class="details-card__content-description--icon">
                  <sh-icon
                    class="is-16x16"
                    :name="'edit'"
                    :file="'tickets_sprite'"
                    v-if="update == false"
                    @click.native="update = true"
                  >
                  </sh-icon>
                </p>
                <sh-icon
                  class="is-16x16 is-pointer"
                  :name="'tick-set-complete'"
                  :file="'tickets_sprite'"
                  v-if="update == true"
                  @click.native="update_description"
                >
                </sh-icon>
              </div>
            </div>
            <div class="details-card__content">
              <div class="details-card__content-heading">
                {{ localization("app-created-on", "Created on") }}
              </div>
              <p class="details-card__content-description sh-text-normal-14">
                {{ details.created_at | dateFormat }}
              </p>
            </div>
            <div class="details-card__content">
              <div class="details-card__content-heading">
                {{ localization("app-asset", "Asset") }}
              </div>
              <primary-tag
                v-if="details.asset"
                :tags="[details.asset]"
                :type="'asset'"
                :noTopMargin="true"
              ></primary-tag>
              <p v-else class="sh-text-normal-14 has-text-grey has-opacity-7">
                *No associated asset
              </p>
            </div>

            <div class="details-card__content">
              <div class="details-card__content-heading">
                {{ localization("app-groups", "Groups") }}
                <span v-if="hasEditAccess">
                  <div @click="add_groups" class="details-card__content__plus">
                    +
                  </div>
                </span>
              </div>
              <p class="details-card__content-description">
                <primary-tag
                  :tags="details.groups"
                  :toShowCount="2"
                  type="group"
                  :noTopMargin="true"
                ></primary-tag>
              </p>
            </div>

            <div
              v-if="$store.getters.is_qc_internal_user"
              class="details-card__content"
            >
              <div class="details-card__content-heading">
                {{ localization("app-qc", "QC") }}
              </div>
              <div class="is-flex">
                <p class="bordered-tag mr-10">
                  <router-link
                    class="is-flex has-text-dark"
                    :to="{
                      name: 'qc-therm-viewer',
                      params: { id: $route.params.id }
                    }"
                  >
                    <img
                      class="sh-app-tag is-pointer image is-24x24"
                      src="@/assets/icons/app-tag/therm-circle.svg"
                    />
                    {{ localization("app-therm", "Therm") }}
                  </router-link>
                </p>
                <p class="bordered-tag">
                  <router-link
                    class="is-flex has-text-dark"
                    :to="{
                      name: 'qc-terra-viewer',
                      params: { id: $route.params.id }
                    }"
                  >
                    <img
                      class="sh-app-tag is-pointer image is-24x24"
                      src="@/assets/icons/app-tag/terra-circle.svg"
                    />
                    {{ localization("app-terra", "Terra") }}
                  </router-link>
                </p>
              </div>
            </div>

            <div class="details-card__content">
              <div class="details-card__content-heading">
                {{ localization("app-applications", "Applications") }}
                <span v-if="hasEditAccess">
                  <div @click="add_apps" class="details-card__content__plus">
                    +
                  </div>
                </span>
              </div>
              <app-type-tag
                :apps="details.app_types"
                :show_name="true"
                :item_id="details.uid"
              ></app-type-tag>
            </div>

            <div class="details-card__content">
              <div class="details-card__content-heading">
                {{ localization("app-users", "Users") }}
                <div
                  v-if="hasEditAccess"
                  @click="
                    add_users({
                      users: details.users,
                      service: containerService,
                      item_uid: details.uid
                    })
                  "
                  class="details-card__content__plus is-pointer"
                >
                  +
                </div>
              </div>
              <p class="details-card__content-description">
                <user-tag
                  v-if="details.users.length"
                  :users="details.users || []"
                  :tagsCountToShow="3"
                  :custom_size="36"
                  :custom_class="'user-style is-medium-14-500-17'"
                ></user-tag>
              </p>
            </div>

            <div class="details-card__content">
              <div class="details-card__content-heading">
                {{ localization("app-size", "Size") }}
              </div>
              <p class="details-card__content-description">
                {{
                  resource_metrics && resource_metrics.data_size
                    ? resource_metrics.data_size / Math.pow(2, 30) + " GB"
                    : "0 GB"
                }}
              </p>
            </div>

            <div class="details-card__content">
              <div class="details-card__content-heading">
                {{ localization("app-tags", "Tags") }}
              </div>
              <p
                class="details-card__content-description"
                v-if="details.tags.length"
              >
                <type-tag
                  :tags="details.tags"
                  :write_access="hasEditAccess"
                  :service="containerService"
                  :complete="
                    () => {
                      $emit('update');
                    }
                  "
                />
              </p>
              <p
                class="details-card__content-description is-flex is-vcenter"
                v-else
              >
                <sh-icon
                  :name="'tags'"
                  class="is-20x20 mr-5 has-opacity-6"
                ></sh-icon>
                <span
                  class="is-regular-14-00-17 has-opacity-7 has-text-light-grey"
                  >{{
                    localization("app-no-tags-assigned", "*No tags assigned")
                  }}</span
                >
              </p>
            </div>
          </template>
          <template slot="footer">
            <div class="is-flex is-vcenter">
              <user-avatar
                :user="
                  details.owner &&
                    details.owner.uid &&
                    $store.getters.state_users[details.owner.uid]
                "
                class="mr-5"
              ></user-avatar>
              <router-link
                class="has-text-dark"
                :to="{
                  name: 'user-details',
                  params: { id: details.owner.uid }
                }"
              >
                <div
                  class="is-medium-14-500-17 has-text-theme-black has-opacity-7"
                >
                  {{ localization("app-owner", "Owner") }}
                </div>
                <div
                  class="is-semiBold-16-600-19 name-highlights-on-hover"
                  v-username="
                    details.owner &&
                      details.owner.uid &&
                      $store.getters.state_users[details.owner.uid]
                  "
                ></div>
              </router-link>
            </div>
          </template>
        </details-sidecard>
      </div>
      <div class="column is-9">
        <div class="sh-tabs">
          <ul>
            <li>
              <router-link
                active-class="is-active"
                :to="{ name: 'container-details' }"
              >
                {{ localization("app-groups", "Groups") }}
                <span>{{
                  (details.groups && details.groups.length) || 0
                }}</span>
              </router-link>
            </li>
          </ul>
        </div>
        <keep-alive>
          <router-view class="router-container-details" />
        </keep-alive>
      </div>
    </div>
    <delete-modal
      v-if="showDeleteModal"
      :resourceId="details.uid"
      :resourceName="details ? details.name : ''"
      :resourceType="'container'"
      :resourceService="containerService"
      @closed="showDeleteModal = false"
      @deleted="
        show_success('Resource successfully deleted');
        $router.push({ name: 'container-list' });
        getData();
      "
    />
  </div>
</template>

<script>
  import AppTypeTag from "@/app/old/desktop/shared/components/tags/app-type-tag";
  import TypeTag from "@/app/old/desktop/shared/components/tags/type-tag/type-tag";
  import PrimaryTag from "@/app/old/desktop/shared/components/tags/primary-tag";
  import detailsSidecard from "@/app/old/desktop/core/shared/components/details-sidecard.vue";
  import { containerService } from "@/app/old/desktop/shared/services/";
  import MainDetailsMedia from "@/app/old/desktop/shared/components/main-details-media";
  import AddApplications from "@/app/old/desktop/core/components/container/components/add-applications.vue";
  import AddGroups from "@/app/old/desktop/core/components/container/components/add-container-groups.vue";
  import UserTag from "@/app/old/desktop/shared/components/tags/user-tag-new";
  import ContainersFormComponent from "@/app/old/desktop/core/components/container/container-form.component.vue";
  import FormPopup from "@/app/old/desktop/shared/mixins/form-popup.mixin.vue";
  import UserTeamPopup from "@/app/old/desktop/shared/mixins/user-team-popup.mixin.vue";
  import DetailsSkeleton from "@/app/old/desktop/shared/components/skeleton/details-skeleton.vue";

  export default {
    props: ["details", "isloading"],
    mixins: [FormPopup, UserTeamPopup],
    data() {
      return {
        containerUsers: [],
        showDeleteModal: false,
        showPinModal: false,
        containerService: containerService,
        resource_metrics: null,
        hasEditAccess: false,
        update: false,
        updated_description:
          this.details && this.details.description
            ? this.details.description.length
            : null,
        ContainersFormComponent: ContainersFormComponent,
        containerService: containerService,
        dropdown: false
      };
    },
    components: {
      detailsSidecard,
      MainDetailsMedia,
      AppTypeTag,
      TypeTag,
      PrimaryTag,
      UserTag,
      DetailsSkeleton
    },
    created() {
      this.checkEditAccess();
    },
    methods: {
      async edit_resource() {
        this.$router.push({ name: "container-edit" });
      },
      open_form_popup(edit_item) {
        let info = {};
        info.header = edit_item ? "Edit Container for" : "Create Container for";
        if (edit_item) {
          info.has_group = edit_item.groups && edit_item.groups.length > 0;
          info.asset_uid = edit_item.asset.uid;
          info.subheader = edit_item.asset.name;
          info.header += " asset";
        }
        this.open_form(this.ContainersFormComponent, info, edit_item);
      },
      async checkEditAccess() {
        containerService
          .get({ id: this.$route.params.id, query: "edit=true" })
          .then(() => (this.hasEditAccess = true))
          .catch(err => this.handleErrors(err));
      },

      async update_description() {
        let res = await containerService.patch({
          body: {
            description: this.updated_description
          },
          id: this.details.uid
        });
        this.details.description = this.updated_description;
        this.update = false;
      },

      add_apps() {
        this.$modal.show(
          AddApplications,
          { apps: this.details.app_types },
          { height: "80%" },
          { name: "add-applications" }
        );
      },
      add_groups() {
        this.$modal.show(
          AddGroups,
          { groups: this.details.groups },
          { height: "80%", width: "25%" },
          { name: "add-groups" }
        );
      }
    }
  };
</script>
