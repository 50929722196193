var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.details)?_c('div',{staticClass:"pin",class:{ 'pin-padding': _vm.$route.name.includes('-vault') }},[_c('path-breadcrumb',{attrs:{"details":_vm.details,"page":_vm.page}}),(_vm.$route.name == _vm.page + '-tickets' || _vm.$route.name == _vm.page + '-vault')?_c('div',{staticClass:"pinned-resource"},[_c('div',{staticClass:"pinned-resource-buttons"},[_c('div',{staticClass:"resource-view mr-10",class:{ 'is-button ': _vm.$route.name == _vm.page + '-tickets' },on:{"click":function($event){_vm.access('tickets')
            ? _vm.$router.push({ name: _vm.page + '-tickets' })
            : _vm.no_access_popup('tickets')}}},[_c('span',{staticClass:"is-flex is-vcenter"},[_c('svg',{staticClass:"image is-21x21 mr-10",attrs:{"viewBox":"0 0 100 100"}},[(_vm.$route.name == _vm.page + '-tickets')?_c('use',{attrs:{"xlink:href":require("@/assets/icons/sprite.svg") + "#tickets-small-active"}}):_c('use',{attrs:{"xlink:href":require("@/assets/icons/sprite.svg") + "#tickets-small"}})]),_c('span',{class:[
              _vm.$route.name == _vm.page + '-tickets'
                ? 'has-text-light'
                : 'has-text-grey-light'
            ]},[_vm._v(_vm._s(_vm.localization("app-tickets", "Tickets")))])])]),_c('div',{staticClass:"resource-view",class:{ 'is-button': _vm.$route.name == _vm.page + '-vault' },on:{"click":function($event){_vm.access('vault')
            ? _vm.$router.push({ name: _vm.page + '-vault' })
            : _vm.no_access_popup('vault')}}},[_c('span',{staticClass:"is-flex is-vcenter"},[_c('svg',{staticClass:"image is-21x21 mr-10",attrs:{"viewBox":"0 0 100 100"}},[(_vm.$route.name == _vm.page + '-vault')?_c('use',{attrs:{"xlink:href":require("@/assets/icons/sprite.svg") + "#vault-small-active"}}):_c('use',{attrs:{"xlink:href":require("@/assets/icons/sprite.svg") + "#vault-small"}})]),_c('span',{class:[
              _vm.$route.name == _vm.page + '-vault'
                ? 'has-text-light'
                : 'has-text-grey-light'
            ]},[_vm._v(_vm._s(_vm.localization("app-vault", "Vault")))])])])])]):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }