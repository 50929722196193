<template>
  <div>
    <transfer-owner
      ref="assetTransferOwner"
      :name="active.name"
      :service="assetService"
      :id="active.uid"
      @update_user="update_owner(active, $event)"
    ></transfer-owner>

    <delete-modal
      v-if="showDeleteModal"
      :resourceId="active.uid"
      :resourceName="active.name"
      :resourceType="'asset'"
      :resourceService="assetService"
      @closed="showDeleteModal = false"
      @deleted="
        show_success('Resource successfully deleted');
        getData();
      "
    />

    <div class="top-section mb-5">
      <div class="top-container">
        <div class="top-container__left is-medium-14-500-17 medium-opacity">
          {{ localization("app-assets-dashboard", "Assets Dashboard") }}
        </div>
        <div class="top-container__right">
          <span>
            <img
              class="image is-16x16 mr-5"
              :src="
                map_visible
                  ? require('@/assets/icons/asset-map-dark.svg')
                  : require('@/assets/icons/asset-map.svg')
              "
              alt
            />
          </span>
          <div
            @click="map_visible = !map_visible"
            :class="[map_visible ? 'map-active' : 'medium-opacity']"
            class="is-medium-14-500-17 is-pointer mr-40"
          >
            {{ localization("app-asset-map", "Asset Map") }}
          </div>
          <div class="is-medium-14-500-17 medium-opacity mr-5">
            {{ localization("app-group-by", "Group By") }}:
          </div>
          <div
            class="sh-dropdown"
            v-click-outside="() => (groupby_active = false)"
          >
            <div
              @click="groupby_active = !groupby_active"
              class="map-active is-pointer mr-40 ml-5"
            >
              <p class="is-flex is-vcenter">
                <span>{{
                  localization(
                    `app-${groupby.name
                      .split(" ")
                      .join("-")
                      .toLowerCase()}`,
                    groupby.name
                  )
                }}</span>
                <sh-icon :name="'chevron-down'" class="is-10x10 ml-5"></sh-icon>
              </p>
            </div>
            <ul class="sh-dropdown-content" v-if="groupby_active" @click.stop>
              <li
                v-for="option in groupby_select"
                :key="option.name"
                class="sh-dropdown-content__item"
                @click="
                  groupby = option;
                  groupby_active = false;
                "
              >
                <div
                  class="sh-dropdown-content__item--text is-medium-16-500-19 is-capitalized"
                >
                  {{
                    localization(
                      `app-${option.name
                        .split(" ")
                        .join("-")
                        .toLowerCase()}`,
                      option.name
                    )
                  }}
                </div>
              </li>
            </ul>
          </div>
          <div class="is-medium-14-500-17 medium-opacity mr-5">
            {{ localization("app-sort-by", "Sort by") }}:
          </div>

          <div
            class="sh-dropdown is-disabled"
            v-click-outside="() => (sortby_active = false)"
          >
            <div @click="sortby_active = false" class="map-active mr-40 ml-5">
              <p class="is-flex is-vcenter">
                <span>{{
                  localization(
                    `app-${sortby.name
                      .split(" ")
                      .join("-")
                      .toLowerCase()}`,
                    sortby.name
                  )
                }}</span>
                <sh-icon :name="'chevron-down'" class="is-10x10 ml-5"></sh-icon>
              </p>
              <!-- {{ sortby.name }}
              <img src="@/assets/icons/chevron-down.svg" alt />-->
            </div>
            <ul class="sh-dropdown-content" v-if="sortby_active" @click.stop>
              <li
                v-for="option in sortby_select"
                :key="option.name"
                class="sh-dropdown-content__item"
              >
                <div
                  class="sh-dropdown-content__item--text is-medium-16-500-19 is-capitalized"
                >
                  {{
                    localization(
                      `app-${option.name
                        .split(" ")
                        .join("-")
                        .toLowerCase()}`,
                      option.name
                    )
                  }}
                </div>
              </li>
            </ul>
          </div>
          <div class="is-medium-14-500-17">
            <div
              v-if="!$store.getters.is_org_user"
              class="list-top-section--create"
            >
              <a
                v-if="access('core', 'create_assets')"
                @click="
                  open_form(AssetFormComponent, { header: 'Create Asset for' })
                "
              >
                <div class="sh-button sh-button--create">
                  {{ localization("app-new-asset", "New Asset") }}
                </div>
              </a>
              <a v-else @click="no_access_popup('core', 'create_assets')">
                <div class="sh-button sh-button--create">
                  {{ localization("app-new-asset", "New Asset") }}
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="columns mr-3 pt-10 pl-10 pb-20">
        <div class="is-flex">
          <searchbar @search="search_query = $event" class=""> </searchbar>
        </div>
      </div>
    </div>
    <div class="main-section" v-if="map_visible">
      <div class="columns">
        <div class="map column">
          <asset-map></asset-map>
        </div>
      </div>
    </div>
    <div v-if="grouped_data">
      <div v-if="isLoading" class="columns px-20 pt-20">
        <div class="column is-3" v-for="(item, index) in 4" :key="index">
          <home-skeleton></home-skeleton>
        </div>
      </div>
      <template v-else>
        <div v-for="(group, heading, index) in grouped_data" :key="index">
          <asset-group
            @disable_asset="disable_asset($event)"
            @delete_asset="
              showDeleteModal = true;
              active = $event;
            "
            @transfer_ownership="
              active = $event;
              $refs.assetTransferOwner.open_popup();
            "
            @asset_form="
              open_form(
                AssetFormComponent,
                { header: 'Edit Asset for' },
                $event
              )
            "
            @open_modal="
              active = $event;
              $store.getters.app_popup_v2
                ? open_choose_apps()
                : (open_app_modal = $event.uid);
            "
            :group="group"
            :index="index"
            :heading="heading"
            :group_key="groupby.value"
          />
        </div>
      </template>
    </div>
    <no-results v-if="no_data">
      <img
        slot="image"
        src="@/assets/illustrations/data-illustration.svg"
        alt
      />
      <div slot="text" class="no-results__text">
        {{ localization("app-no-assets-found", "No assets found") }}
      </div>
      <div slot="button">
        <div
          @click="
            access('core', 'create_assets')
              ? open_form(AssetFormComponent, { header: 'Create Asset for' })
              : no_access_popup('core', 'create_assets')
          "
          class="sh-button sh-button--create"
        >
          {{ localization("app-create-new-asset", "Create New Asset") }}
        </div>
      </div>
    </no-results>
    <b-loading
      v-if="is_apps_loading"
      :is-full-page="true"
      :active.sync="is_apps_loading"
      :can-cancel="true"
    ></b-loading>
    <b-modal
      class="applications-container-small"
      :active.sync="open_app_modal"
      :width="974"
      scroll="keep"
    >
      <div class="applications-container">
        <div class="card">
          <div class="card-header" style="box-shadow: none">
            <div
              class="card-header-title is-capitalized is-semibold-18-600-22 modal-title"
            >
              {{ localization("app-choose-application", "Choose Application") }}
            </div>
          </div>
          <div class="card-content" style="padding: 1rem 3rem 3rem 3rem">
            <div
              class="application-card"
              v-for="app in applications"
              :key="app.name"
            >
              <div
                v-if="app.split_permissions"
                class="card is-pointer"
                @click="goto_app(app)"
              >
                <div class="columns">
                  <div class="column is-3 is-paddingless">
                    <figure class="app-icon pt-5">
                      <img
                        :src="
                          app.icon_round ||
                            'https://bulma.io/images/placeholders/1280x960.png'
                        "
                        alt="cover_image"
                      />
                    </figure>
                  </div>
                  <div class="column is-paddingless">
                    <div
                      class="card-content is-paddingless is-block application-card__details__text"
                    >
                      <p class="is-medium-18-500-22 mt-5">
                        {{ localization(`app-${app.name}`, app.name) }}
                      </p>
                      <p
                        class="application-description is-regular-14-500-22 mt-5"
                      >
                        {{
                          localization(
                            `app-${app.description}`,
                            app.description
                          )
                        }}
                      </p>
                      <router-link
                        v-if="
                          app.permissions_key || access(app.permissions_key)
                        "
                        :to="
                          app.to == 'sm-template'
                            ? {
                                name: app.to,
                                params: { asset: open_app_modal }
                              }
                            : {
                                name: app.to,
                                query: { asset: open_app_modal }
                              }
                        "
                      >
                        <div class="is-inline-flex mt-20">
                          <p
                            class="is-pointer is-large is-white is-medium-16-500-19 has-text-blue"
                          >
                            {{
                              localization(
                                "app-view-application",
                                "View Application"
                              )
                            }}
                          </p>
                          <div class="ml-5">
                            <img
                              src="@/assets/icons/right-arrow-active.svg"
                              alt
                            />
                          </div>
                        </div>
                      </router-link>
                      <a
                        v-else
                        @click.stop="no_access_popup(app.permissions_key)"
                      >
                        <div class="is-inline-flex mt-20">
                          <p
                            class="is-pointer is-large is-white is-medium-16-500-19 has-text-blue"
                          >
                            {{
                              localization(
                                "app-view-application",
                                "View Application"
                              )
                            }}
                          </p>
                          <div class="ml-5">
                            <img
                              src="@/assets/icons/right-arrow-active.svg"
                              alt
                            />
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import {
    assetService,
    viewService,
    terraService
  } from "@/app/old/desktop/shared/services/";

  import AssetFormComponent from "@/app/old/desktop/core/components/asset/asset-form.component.vue";
  import AssignedTags from "@/app/old/desktop/shared/components/tags/type-tag/components/assigned-tags";
  import AssetMap from "./assetmap.component";
  import FormPopup from "@/app/old/desktop/shared/mixins/form-popup.mixin.vue";
  import HomeSkeleton from "@/app/old/desktop/shared/components/skeleton/home-skeleton.vue";
  import searchbar from "@/app/old/desktop/core/shared/components/searchbar";
  import ChooseApps from "./choose-apps";
  import AssetGroup from "./asset-group.vue";

  const queryString = require("query-string");

  export default {
    mixins: [FormPopup],
    components: {
      AssignedTags,
      AssetMap,
      HomeSkeleton,
      AssetGroup,
      searchbar
    },
    data() {
      return {
        search_query: "",
        isLoading: false,
        is_apps_loading: false,
        assetService: assetService,
        open_app_modal: false,
        active: { uid: null },
        showDeleteModal: false,
        groupby: { value: "", name: "All" },
        sortby: { value: "", name: "None" },
        map_visible: false,
        groupby_active: false,
        sortby_active: false,
        sortby_select: [
          {
            name: "Location",
            value: ""
          }
        ],
        AssetFormComponent: AssetFormComponent,
        groupby_select: [
          {
            name: "All",
            value: ""
          },
          {
            name: "Entity",
            value: "entity.name"
          },
          {
            name: "Type",
            value: "asset_type"
          },
          {
            name: "Tags",
            value: "tags"
          }
        ],
        applications: [
          {
            name: "Terra",
            image: require("@/assets/icons/png/terra-app.png"),
            icon: require("@/assets/icons/png/terra.png"),
            icon_round: require("@/assets/icons/png/terra-round.png"),
            to: "terra-list",
            description: "Monitoring solution and other terrain tools.",
            permissions_key: "terra",
            split_permissions: true
          },
          {
            name: "Thermal",
            image: require("@/assets/icons/png/thermal-app.png"),
            icon: require("@/assets/icons/png/thermal.png"),
            icon_round: require("@/assets/icons/png/thermal-round.png"),
            to: "therm-view-list",
            description: "Find defects that degrade the site performance.",
            permissions_key: "therm",
            split_permissions: true
          },
          {
            name: "Processing",
            image: require("@/assets/icons/png/processing-app.png"),
            icon: require("@/assets/icons/png/processing.png"),
            icon_round: require("@/assets/icons/png/processing-round.png"),
            to: "process-list",
            description: "Photogrammetry and various other tools.",
            permissions_key: "processing",
            split_permissions: true
          },
          {
            name: "Missions",
            image: require("@/assets/icons/png/missions-app.png"),
            icon: require("@/assets/icons/png/missions.png"),
            icon_round: require("@/assets/icons/png/missions-round.png"),
            to: "mission-list",
            description:
              "Plan a flight path and use our link mobile app to fly.",
            permissions_key: "missions",
            split_permissions: true
          },
          // {
          //   name: "Tasks",
          //   image: require("@/assets/icons/png/tickets-app.png"),
          //   icon: require("@/assets/icons/png/tickets.png"),
          //   icon_round: require("@/assets/icons/png/tickets-round.png"),
          //   to: "tickets",
          //   description:
          //     "Fix defects on site with tasks and better collaboration.",
          //   permissions_key: "tickets",
          //   split_permissions: true
          // },
          // {
          //   name: "Vault",
          //   image: require("@/assets/icons/png/vault-app.png"),
          //   icon: require("@/assets/icons/png/vault.png"),
          //   icon_round: require("@/assets/icons/png/vault-round.png"),
          //   to: "vault",
          //   description:
          //     "Store files, lock and share them with your teammates.",
          //   permissions_key: "vault",
          //   split_permissions: true
          // },
          // {
          //   name: "Forms",
          //   image: require("@/assets/icons/forms-logo.svg"),
          //   icon: require("@/assets/icons/forms-logo.svg"),
          //   icon_round: require("@/assets/icons/png/form-round.png"),
          //   to: "forms",
          //   description:
          //     "Collect, Consolidate and analyze your on field data on one platform.",
          //   // permissions_key: "forms",
          //   permissions_key: true,
          //   split_permissions: this.$store.getters.forms
          // },
          {
            name: "System Model",
            image: require("@/assets/icons/png/vault-app.png"),
            icon: require("@/assets/icons/png/vault.png"),
            icon_round: require("@/assets/icons/png/vault-round.png"),
            to: "sm-template",
            description:
              "Store files, lock and share them with your teammates.",
            permissions_key: true,
            split_permissions: this.$store.getters.system_model
          }
        ]
      };
    },
    computed: {
      ...mapState(["assets"]),
      filtered_assets() {
        const search_query = this.search_query.toLowerCase();
        return this.assets?.filter(asset =>
          asset.name.toLowerCase().includes(search_query)
        );
      },
      no_data() {
        return !this.isLoading && _.isEmpty(this.grouped_data);
      },
      grouped_data() {
        let property = this.groupby.value;
        let grps = _.groupBy(this.filtered_assets, property);
        return grps;
      }
    },
    created() {
      if (!this.assets || !this.assets.length) this.getData();
      this.$eventHub.$on("refresh-asset-list", this.getData);
    },
    beforeDestroy() {
      this.$eventHub.$off("refresh-asset-list", this.getData);
    },
    methods: {
      async open_choose_apps() {
        this.is_apps_loading = true;
        let terra = [];
        if (this.has_app_access("terra")) terra = await this.fetch_terra_data();

        let therm = [];
        if (this.has_app_access("therm")) therm = await this.fetch_therm_data();

        this.is_apps_loading = false;
        if (terra.length || therm.length)
          this.$modal.show(
            ChooseApps,
            { asset: this.active, terra, therm },
            {
              height: "auto",
              width: "740px",
              classes: ["choose-card", "has-background-transparent"],
              scrollable: true
            },
            { name: "choose-apps" }
          );
        else this.open_app_modal = this.active.uid;
      },
      fetch_terra_data() {
        return terraService
          .get_views({
            query: `asset=${this.active.uid}`
          })
          .then(res => res.data)
          .catch(err => {
            return [];
          });
      },
      fetch_therm_data() {
        let query = {
          metrics: true,
          complete: true,
          projects: true,
          important: true,
          page_size: 1000,
          page_number: 1,
          assetUid: this.active.uid
        };
        return viewService
          .getAll({
            query: queryString.stringify(query)
          })
          .then(res => res.data)
          .catch(err => {
            return [];
          });
      },
      goto_app(app) {
        if (
          app.permissions_key === true ||
          this.has_app_access(app.permissions_key)
        )
          this.$router.push(
            app.to == "sm-template"
              ? {
                  name: app.to,
                  params: { asset: this.asset.uid }
                }
              : {
                  name: app.to,
                  query: { asset: this.asset.uid }
                }
          );
        else this.no_access_popup(app.permissions_key);
      },
      tagslist(key) {
        return key.toString().split(",");
      },
      public_asset_count(group) {
        return group.data.filter(obj => obj.public == true).length;
      },
      toggle_group_visibility(group) {
        group.visible = !group.visible;
        //fix for not updating the dom after toggle
        this.$forceUpdate();
      },
      async disable_asset(data) {
        let res = await assetService.patch({
          body: {
            public: !data.is_disable
          },
          id: data.id
        });
        if (res.success) {
          if (!data.is_disable == false) {
            this.$toast.show(
              "Asset disabled successfully",
              "",
              this.$store.state.izitoast_options.appSuccess
            );
          }
          this.getData(false);
        } else this.$toast.show("Failed to complete the action");
      },
      async getData(is_loading = true) {
        try {
          // if (this.assets) return;
          this.isLoading = is_loading;
          let query = "page_size=1000&page_number=1";
          //   query.labels = true;
          let { results } = await assetService.getAssetsDashboard({ query });
          // this.assets = results;
          this.$store.commit("SET_ASSETS", results);
          this.isLoading = false;
        } catch (err) {
          this.handleErrors(err);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .map-active {
    opacity: 1;
    font-family: Barlow-semibold;
    font-size: 14px;
    font-weight: 600;
    color: #171e48;
  }
  .main-section {
    margin: 2rem;
    margin-bottom: 0;
    height: 50rem;
    background-color: #f4f7fc;
  }
  .top-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__right {
      display: flex;
      align-items: center;
      color: #393c42;
      .select {
        margin-top: 3px;
        background-color: transparent;
        .select-value {
          font-family: Barlow-semibold;
          font-size: 14px;
          font-weight: 600;
          color: #171e48;
        }
        select {
          background-color: transparent;
        }
      }
    }
    &__left {
      color: #393c42;
    }
    .medium-opacity {
      opacity: 0.7;
    }
  }

  .applications-container .card-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .application-card {
      .card {
        padding: 2.5rem 2.5rem 2.5rem 3rem;
        border-radius: 10px;
        border: solid 1px rgba(23, 30, 72, 0.15);
      }
      .card:hover {
        box-shadow: 0 5px 10px 0 rgba(92, 126, 247, 0.3);
        border: solid 1px $primary;
      }
      .application-description {
        text-align: left;
        color: #171e48;
        font-size: 14px;
      }
    }
  }
  .modal-title {
    font-family: Barlow-semibold;
    font-size: 20px;
    font-weight: 600;
    color: #171e48;
    padding: 15px 4rem !important;
  }
  @media (min-width: 300px) {
    .application-card {
      width: 50%;
      padding: 1rem;
    }
  }
  @media (min-width: 600px) {
    .application-card {
      width: 33.33%;
      padding: 1rem;
    }
  }
  .sh-dropdown-content {
    width: 10.5rem;
    box-shadow: 10px 20px 50px 0 rgba(23, 30, 72, 0.2);
    border: solid 1px rgba(23, 30, 72, 0.15);
    right: 0rem;
    top: 2.5rem;
    padding: 1rem 0;
    border-radius: 1rem;
    &__item {
      padding: 0.5rem 2rem;
    }
  }
  .sh-dropdown-content__item--text {
    padding: 0.3rem 0rem;
    span {
      white-space: nowrap;
      line-height: 50px;
    }
  }
</style>
