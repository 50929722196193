var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('skeleton-loading',_vm._l((_vm.items.row_count),function(i){return _c('row',{key:i,staticClass:"sh-skeleton is-flex align-center",attrs:{"gutter":{ top: '25px', bottom: '20px' }}},[_vm._l((_vm.items.name),function(i){return _c('column',{key:'name' + i,attrs:{"span":24 / _vm.items.col_count,"gutter":5}},[_c('row',{staticClass:"is-flex align-center"},[_c('column',{attrs:{"span":4}},[_c('circle-skeleton')],1),_c('column',{attrs:{"span":20,"gutter":10}},[_c('row',[_c('column',{attrs:{"span":10}},[_c('square-skeleton',{attrs:{"boxProperties":{
                  bottom: '10px',
                  width: '100%',
                  height: '10px'
                }}})],1)],1),_c('row',[_c('column',{attrs:{"span":20}},[_c('square-skeleton',{attrs:{"boxProperties":{
                  bottom: '10px',
                  height: '20px'
                }}})],1)],1)],1)],1)],1)}),_vm._l((_vm.items.team),function(item){return _c('column',{key:'teams' + item,attrs:{"span":24 / _vm.items.col_count}},[_c('row',[_c('column',{attrs:{"span":8}},[_c('square-skeleton',{attrs:{"boxProperties":{
              bottom: '10px',
              width: '100%',
              height: '10px'
            }}})],1)],1),_c('row',[_c('column',{attrs:{"span":8}},[_c('square-skeleton',{attrs:{"boxProperties":{
              bottom: '10px',
              height: '25px'
            }}})],1),_c('column',{attrs:{"span":12,"gutter":5}},[_c('square-skeleton',{attrs:{"boxProperties":{
              bottom: '10px',
              height: '25px'
            }}})],1)],1)],1)}),_vm._l((_vm.items.assets),function(item){return _c('column',{key:item,attrs:{"span":24 / _vm.items.col_count}},[_c('row',[_c('column',{attrs:{"span":8}},[_c('square-skeleton',{attrs:{"boxProperties":{
              bottom: '10px',
              width: '100%',
              height: '10px'
            }}})],1)],1),_c('row',[_c('column',{attrs:{"span":8}},[_c('square-skeleton',{attrs:{"boxProperties":{
              bottom: '10px',
              height: '25px'
            }}})],1),_c('column',{attrs:{"span":12,"gutter":5}},[_c('square-skeleton',{attrs:{"boxProperties":{
              bottom: '10px',
              height: '25px'
            }}})],1)],1)],1)}),_vm._l((_vm.items.application),function(item){return _c('column',{key:item,attrs:{"span":24 / _vm.items.col_count,"gutter":10}},[_c('row',[_c('column',{attrs:{"span":8}},[_c('square-skeleton',{attrs:{"boxProperties":{
              bottom: '10px',
              height: '10px'
            }}})],1)],1),_c('row',[_c('column',{attrs:{"span":5,"gutter":5}},[_c('circle-skeleton')],1),_c('column',{attrs:{"span":5,"gutter":5}},[_c('circle-skeleton')],1),_c('column',{attrs:{"span":5,"gutter":5}},[_c('circle-skeleton')],1),_c('column',{attrs:{"span":5,"gutter":5}},[_c('circle-skeleton')],1)],1)],1)}),_vm._l((_vm.items.tags),function(item){return _c('column',{key:'tags' + item,attrs:{"span":24 / _vm.items.col_count,"gutter":10}},[_c('row',[_c('column',{attrs:{"span":8}},[_c('square-skeleton',{attrs:{"boxProperties":{
              bottom: '10px',
              width: '100%',
              height: '10px'
            }}})],1)],1),_c('row',[_c('column',{attrs:{"span":4}},[_c('square-skeleton',{attrs:{"boxProperties":{
              bottom: '10px',
              height: '25px'
            }}})],1),_c('column',{attrs:{"span":8,"gutter":5}},[_c('square-skeleton',{attrs:{"boxProperties":{
              bottom: '10px',
              height: '25px'
            }}})],1),_c('column',{attrs:{"span":6,"gutter":5}},[_c('square-skeleton',{attrs:{"boxProperties":{
              bottom: '10px',
              height: '25px'
            }}})],1)],1)],1)}),_vm._l((_vm.items.user),function(item){return _c('column',{key:'user' + item,attrs:{"span":24 / _vm.items.col_count,"gutter":10}},[_c('row',[_c('column',{attrs:{"span":8}},[_c('square-skeleton',{attrs:{"boxProperties":{
              bottom: '10px',
              height: '10px'
            }}})],1)],1),_c('row',[_c('column',{attrs:{"span":5,"gutter":5}},[_c('circle-skeleton')],1),_c('column',{attrs:{"span":5,"gutter":5}},[_c('circle-skeleton')],1),_c('column',{attrs:{"span":5,"gutter":5}},[_c('circle-skeleton')],1),_c('column',{attrs:{"span":5,"gutter":5}},[_c('circle-skeleton')],1)],1)],1)}),_vm._l((_vm.items.text),function(item){return _c('column',{key:item,attrs:{"span":24 / _vm.items.col_count,"gutter":10}},[_c('row',[_c('column',{attrs:{"span":8}},[_c('square-skeleton',{attrs:{"boxProperties":{
              bottom: '10px',
              width: '100%',
              height: '10px'
            }}})],1)],1),_c('row',[_c('column',{attrs:{"span":20}},[_c('square-skeleton',{attrs:{"boxProperties":{
              bottom: '10px',
              height: '25px'
            }}})],1)],1)],1)}),_vm._l((_vm.items.status),function(item){return _c('column',{key:'status' + item,attrs:{"span":24 / _vm.items.col_count,"gutter":10}},[_c('row',{attrs:{"justify":"center"}},[_c('column',{attrs:{"span":8}},[_c('square-skeleton',{attrs:{"boxProperties":{
              bottom: '10px',
              height: '10px'
            }}})],1)],1),_c('row',{attrs:{"justify":"center"}},[_c('column',{attrs:{"span":12}},[_c('square-skeleton',{attrs:{"boxProperties":{
              bottom: '10px',
              height: '25px'
            }}})],1)],1)],1)})],2)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }