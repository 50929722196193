var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((3),function(item){return _c('skeleton-loading',{key:item,staticClass:"sh-skeleton"},[_c('row',{attrs:{"gutter":{ top: '10px', bottom: '10px' }}},[_c('column',{attrs:{"span":20,"gutter":20}},[_c('square-skeleton',{attrs:{"boxProperties":{
            top: '5px',
            width: '100%',
            height: '10px'
          }}}),_c('square-skeleton',{attrs:{"boxProperties":{
            width: '40%',
            height: '30px',
            top: '10px'
          }}})],1)],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }