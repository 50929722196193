var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dragitem vault__grid-item",on:{"click":[function($event){if(!$event.shiftKey){ return null; }$event.stopPropagation();if($event.ctrlKey||$event.altKey||$event.metaKey){ return null; }return _vm.$emit('shift_click', _vm.item)},function($event){if(!$event.metaKey){ return null; }if($event.ctrlKey||$event.shiftKey||$event.altKey){ return null; }return _vm.$emit('toggle_selection', _vm.item)},function($event){if(!$event.ctrlKey){ return null; }if($event.shiftKey||$event.altKey||$event.metaKey){ return null; }return _vm.$emit('toggle_selection', _vm.item)}]}},[_c('div',[_c('div',{staticClass:"vault__grid-item-thumbnail is-flex align-center is-center",class:{
        'blue-border': _vm.$store.state.vault.selected_folders.includes(_vm.item.uid)
      }},[_c('div',{staticClass:"bookmark",class:{ 'bookmark-hidden': !_vm.item.bookmark },on:{"click":function($event){return _vm.bookmark(_vm.item, _vm.i)}}},[_c('sh-icon',{attrs:{"name":_vm.item.bookmark ? 'bookmark-selected' : 'bookmark',"file":'vault_sprite'}})],1),_c('div',[(_vm.$route.name == 'vault')?_c('router-link',{attrs:{"to":{
            params: { folder_id: _vm.item.uid },
            query: { asset: _vm.$route.query.asset }
          }}},[_c('sh-icon',{staticClass:"vault-folder-icon",attrs:{"name":'add-folder',"file":'vault_sprite'}})],1):_c('a',{nativeOn:{"click":function($event){return _vm.$store.commit('vault/SET_CURRENT_FOLDER_UID', _vm.item.uid)}}},[_c('sh-icon',{staticClass:"vault-folder-icon",attrs:{"name":'add-folder',"file":'vault_sprite'}})],1)],1)]),_c('div',{staticClass:"vault__grid-item-content"},[_c('div',{staticClass:"is-flex align-center is-between"},[_c('div',{staticClass:"mt-5"},[(_vm.is_edit === _vm.i)?[_c('input',{directives:[{name:"focus",rawName:"v-focus"},{name:"click-outside",rawName:"v-click-outside",value:(function () { return (_vm.is_edit = -1); }),expression:"() => (is_edit = -1)"},{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"is-fullwidth input is-medium-14-500-17",attrs:{"type":"text"},domProps:{"value":(_vm.form.name)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.save()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}})]:_c('p',{staticClass:"is-inline-flex align-center"},[(_vm.$route.name == 'vault')?_c('router-link',{attrs:{"to":{
                params: { folder_id: _vm.item.uid },
                query: { asset: _vm.$route.query.asset }
              }}},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.item.name.length > 10 ? _vm.item.name : null),expression:"item.name.length > 10 ? item.name : null"}],staticClass:"name is-medium-14-500-17"},[_vm._v(_vm._s(_vm._f("truncate")(_vm.item.name,10)))])]):_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.item.name.length > 10 ? _vm.item.name : null),expression:"item.name.length > 10 ? item.name : null"}],staticClass:"name  is-medium-14-500-17",nativeOn:{"click":function($event){return _vm.$store.commit('vault/SET_CURRENT_FOLDER_UID', _vm.item.uid)}}},[_vm._v(_vm._s(_vm._f("truncate")(_vm.item.name,10)))]),(_vm.item.passwordProtected)?_c('span',{staticClass:"lock ml-5"},[_c('sh-icon',{staticClass:"image is-pointer is-20x20",attrs:{"file":'vault_sprite',"name":'lock'}})],1):_vm._e(),_c('span',{staticClass:"download"},[_c('a',{on:{"click":function($event){return _vm.download_files(_vm.item)}}},[_c('sh-icon',{staticClass:"mr-10 ml-10 is-pointer is-14x14",attrs:{"file":'tickets_sprite',"name":'download-blue'}})],1)])],1)],2),(_vm.is_edit !== _vm.i)?_c('folder-dropdown',{staticClass:"file-actions",attrs:{"folder":_vm.item},on:{"download":function($event){return _vm.download_files(_vm.item)},"archive":function($event){return _vm.archive(_vm.item)},"delete":function($event){return _vm.delete_item(_vm.item)},"lock_unlock_folder":_vm.open_lock_unlock,"reset_password":_vm.reset_password,"move":function($event){return _vm.move($event)},"rename":function($event){_vm.is_edit = _vm.i;
            _vm.form.name = _vm.item.name;},"share":function($event){return _vm.add_users(_vm.item, 'folder')}}}):_vm._e()],1),_c('div',[(_vm.item.archive)?_c('span',{staticClass:"archive is-inline is-medium-12-500-14 has-opacity-6 is-pointer mr-5 has-opacity-7",on:{"click":function($event){$event.stopPropagation();return _vm.archive(_vm.item)},"mouseenter":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},[_vm._v(_vm._s(_vm.hover ? "Unarchive" : _vm.localization("app-archived", "Archived")))]):_vm._e()]),(_vm.item.ancestors && _vm.item.ancestors.length && !_vm.is_everything)?_c('div',{staticClass:"is-flex align-center",staticStyle:{"flex-wrap":"wrap"}},[_c('sh-icon',{staticClass:"image is-pointer is-16x16",attrs:{"file":'vault_sprite',"name":'add-folder'}}),_vm._l((_vm.item.ancestors),function(crumb,i){return _c('p',{key:crumb.uid,staticClass:"is-medium-12-500-14 mr-5 is-pointer is-inline-flex align-center is-capitalized has-text-grey has-opacity-7",on:{"click":function($event){!_vm.$route.name.includes('viewer')
              ? _vm.$router.push({
                  params: { folder_id: crumb.uid },
                  query: { asset: _vm.$route.query.asset }
                })
              : _vm.$store.commit('vault/SET_CURRENT_FOLDER_UID', crumb.uid)}}},[_vm._v(" "+_vm._s(crumb.name)+" "),(i !== _vm.item.ancestors.length - 1)?_c('span',{staticClass:"ml-5"},[_vm._v(">")]):_vm._e()])})],2):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }