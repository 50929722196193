<template>
  <div class="invite" @keyup.enter="add_user">
    <div class="invite__header mt-10 columns">
      <div class="is-capitalized is-medium-16-500-19 column">
        {{ localization("app-email-address", "Email Address") }}
      </div>
      <div class="is-capitalized is-medium-16-500-19 column">
        {{ localization("app-full-name", "Full name") }}
      </div>
      <i
        class="is-vhidden fa fa-times column is-1 fa-2x has-text-grey-light is-pointer"
        aria-hidden="true"
      ></i>
    </div>

    <div class="mb-5 columns" v-for="(user, index) in users" :key="index">
      <div class="is-medium-16-500-19 column ">{{ user.email }}</div>
      <div class="is-medium-16-500-19 column ">{{ user.name || "None" }}</div>
      <i
        @click="users.splice(index, 1)"
        class="fa fa-times column is-1 fa-2x has-text-grey-light is-pointer"
        aria-hidden="true"
      ></i>
    </div>

    <div
      class="columns mb-20 align-center"
      v-if="show_form || !users.length"
      v-click-outside="() => add_user()"
    >
      <div class="column">
        <input
          @keyup.enter="add_user"
          v-model="form.email"
          v-focus
          type="email"
          class="sh-form__content__input mr-10"
          :placeholder="
            localization(
              'app-type-email-and-enter',
              'Type email address and press enter'
            )
          "
          :class="{
            'sh-form__content__input--error': form.email && !is_valid_email
          }"
        />
        <p
          v-if="form.email && form.email.length && !is_valid_email"
          class="is-absolute"
        >
          Please enter valid email address
        </p>
      </div>
      <div class="column">
        <input
          @keyup.enter="add_user()"
          v-model="form.name"
          type="text"
          class="sh-form__content__input"
          :placeholder="localization('app-type-name', 'Firstname Lastname')"
          :class="{
            'sh-form__content__input--error':
              form.name && form.name.length && !is_valid_full_name
          }"
        />
        <p
          v-if="form.name && form.name.length && !is_valid_full_name"
          class="is-absolute"
        >
          Please enter full name

          <i>(John Smith)</i>
        </p>
      </div>
      <i
        class="fa fa-check column is-1 fa-2x  is-pointer is-paddingless"
        :class="[
          is_valid_email && is_valid_full_name
            ? 'has-text-success'
            : 'is-disabled has-text-grey-light'
        ]"
        aria-hidden="true"
        @click="add_user"
      ></i>
    </div>

    <div class="mt-40">
      <p class="is-medium-16-500-19">{{ localization("app-note", "Note") }}</p>
      <p class="is-regular-16-00-19 mt-20">
        {{
          localization(
            "app-text-user-invite-info",
            "Users will receive an email to signup your organization."
          )
        }}
      </p>
    </div>
    <div class="invite-container__footer">
      <div class="buttons">
        <div
          @click="$emit('back')"
          style="background-color:white;color:#171e48;opacity:0.6"
          class="sh-button is-pulled-right is-semiBold-14-600-17 mr-10"
        >
          {{ localization("app-back", "Back") }}
        </div>
        <div v-if="awaitingResponse" class="sh-button is-pulled-right">
          <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
        </div>
        <div
          v-else
          :class="[!users.length ? 'is-disabled' : '']"
          @click="save"
          class="is-pulled-right sh-button is-semiBold-14-600-17"
        >
          {{ localization("app-invite-new-users", "Invite New Users") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { userService } from "@/app/old/desktop/shared/services/";
  export default {
    data() {
      return {
        users: [],
        form: {
          name: "",
          email: ""
        },
        show_form: true,
        awaitingResponse: false
      };
    },
    computed: {
      is_valid_email() {
        return this.validate(this.form.email);
      },
      is_valid_full_name() {
        if (this.form.name && this.form.name.length) {
          let fullname = this.form.name.split(" ");
          return fullname[0] &&
            fullname[0].length &&
            fullname[1] &&
            fullname[1].length
            ? true
            : false;
        } else {
          return false;
        }
      }
    },
    mounted() {},
    methods: {
      add_user(show = true) {
        if (!this.is_valid_email || !this.is_valid_full_name) return;
        this.form.email = this.form.email.toLowerCase();
        this.users.push(this.form);
        this.form = { name: "", email: "" };
        this.show_form = show;
      },
      validate(email) {
        if (email) {
          let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(email.toLowerCase());
        }
      },
      async save() {
        try {
          this.awaitingResponse = true;
          let userResult = await userService.invite_users({
            body: {
              users: this.users.map(user => ({
                email: user.email,
                name: user.name
              }))
            }
          });
          this.show_success("Users invited successfully.");
          this.$eventHub.$emit("refresh-user-list");
          this.$emit("close");
          this.awaitingResponse = false;
        } catch (err) {
          this.awaitingResponse = false;
          this.show_danger("Somthing went wrong.");
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .invite {
    .sh-form__content__input {
      margin-top: 0;
      background-color: #f4f7fc;
    }
  }
</style>
