var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"columns is-flex is-vcenter"},[_c('div',{staticClass:"column is-4 is-flex align-center"},[_c('div',{staticClass:"b-checkbox mr-15",staticStyle:{"margin-top":"2px"},on:{"click":function($event){return _vm.$emit('add_to_selection', _vm.item.uid)}}},[_c('input',{staticClass:"sh-custom-checkbox is-thinner fill",attrs:{"type":"checkbox","name":"project","id":_vm.item.uid},domProps:{"checked":_vm.selected.includes(_vm.item.uid)}})]),_c('div',[_c('h4',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.item.name.length > 16 ? _vm.item.name : null,
            html: false
          }),expression:"{\n            content: item.name.length > 16 ? item.name : null,\n            html: false\n          }"}],staticClass:"is-marginless is-marginless no-whitespace-wrap is-semiBold-16-600-19",class:_vm.selected.includes(_vm.item.uid)
              ? 'has-text-blue'
              : 'has-text-theme-black'},[_c('router-link',{staticClass:"has-primary-text mr-5",attrs:{"to":{
              name: 'project-details',
              params: { group_id: _vm.item.group.uid, id: _vm.item.uid }
            }}},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.item.name,16))+" ")]),(_vm.$store.getters.is_internal_user)?_c('span',{on:{"click":function($event){_vm.group_disabled = !_vm.group_disabled;
              _vm.update_properties(_vm.item);}}},[(_vm.group_disabled)?_c('i',{staticClass:"fa fa-times is-pointer",staticStyle:{"color":"red"},attrs:{"aria-hidden":"true"}}):_c('i',{staticClass:"fa fa-check is-pointer",staticStyle:{"color":"green"},attrs:{"aria-hidden":"true"}})]):_vm._e()],1),_c('p',{staticClass:"is-marginless is-medium-14-500-17 has-text-theme-black has-opacity-7 is-capitalized pt-5"},[_vm._v(" "+_vm._s(_vm.item.no_of_images)+" "+_vm._s(_vm.localization("app-images", "images"))+" ")])])]),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return (_vm.cal_active = false); }),expression:"() => (cal_active = false)"}],staticClass:"column",class:[_vm.$route.name == 'group-list' ? 'is-3' : 'is-4']},[(_vm.item.properties && _vm.item.properties.date)?_c('p',{staticClass:"is-marginless is-medium-16-500-19 no-whitespace-wrap  is-pointer is-flex is-vcenter",on:{"click":function($event){_vm.cal_active = !_vm.cal_active;
          _vm.create_cal();}}},[_vm._v(" "+_vm._s(new Date(_vm.item.properties.date).toLocaleDateString())+" "),(_vm.hover == _vm.item.uid)?_c('span',{staticClass:"ml-5",on:{"click":function($event){$event.stopPropagation();return _vm.remove_date()}}},[_c('sh-icon',{staticClass:"is-12x12",attrs:{"name":'close'}})],1):_vm._e()]):_c('p',{staticClass:"is-marginless is-regular-14-00-17 has-text-blue-grey is-pointer",on:{"click":function($event){_vm.cal_active = !_vm.cal_active;
          _vm.create_cal();}}},[_vm._v(" "+_vm._s(_vm.localization("app-set-date", "Set Date"))+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.cal_active),expression:"cal_active"}]},[_c('div',{attrs:{"id":("" + ('project_list_cal-' + _vm.item.uid))}})])]),(_vm.$route.name == 'group-list')?_c('div',{staticClass:"column is-3"},[(_vm.access('missions'))?_c('div',[_c('div',{staticClass:"is-flex align-center"},[_c('p',{staticClass:"is-marginless is-medium-16-500-19",class:[_vm.item.missions ? null : 'text-inactive']},[_vm._v(" "+_vm._s(_vm.localization( "app-no-associated-missions", "*No associated missions" ))+" ")])])]):_c('div',{staticClass:" is-not-allowed",on:{"click":function($event){return _vm.no_access_popup('missions')}}},[_c('p',{staticClass:"is-marginless is-medium-16-500-19",class:[_vm.item.missions ? null : 'text-inactive']},[_vm._v(" "+_vm._s(_vm.localization("app-no-access", "*No access"))+" ")])])]):_vm._e(),_c('div',{staticClass:"column is-flex is-between align-center",class:_vm.$route.name == 'group-list' ? 'is-2' : 'is-3'},[(_vm.access('processing') && _vm.$store.getters.is_qc_internal_user)?_c('div',[_c('p',{staticClass:"processing",class:[
            _vm.item.process && _vm.item.process.status
              ? 'has-text-dark is-' + _vm.item.process.status
              : 'is-start is-pointer'
          ],staticStyle:{"height":"30px"},on:{"click":function($event){_vm.access('processing')
              ? _vm.create_process(_vm.item)
              : _vm.no_access_popup('processing')}}},[(_vm.item.process && _vm.item.process.status)?_c('sh-icon',{staticClass:"mr-5 is-24x24",attrs:{"name":_vm.item.process.status + '-colored'}}):_c('sh-icon',{staticClass:"mr-5 is-16x16",attrs:{"name":'start-processing'}}),_vm._v(" "+_vm._s(_vm.item.process && _vm.item.process.status ? _vm.localization( ("app-" + (_vm.item.process.status)), _vm.item.process.status ) : _vm.localization("app-start", "start"))+" ")],1)]):_c('div',{staticClass:"is-regular-14-00-17 has-opacity-6 no-whitespace-wrap"},[_vm._v(" "+_vm._s(_vm.localization("app-no-access", "*No access"))+" ")])]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"actions-dropdown",on:{"click":function($event){_vm.active == _vm.item.uid ? (_vm.active = -1) : (_vm.active = _vm.item.uid)}}},[_c('div',{staticClass:"actions-dropdown-selection",class:[
            _vm.hover == _vm.item.uid || _vm.active == _vm.item.uid ? null : 'is-hidden'
          ]},[_c('sh-icon',{staticClass:"is-20x20",attrs:{"name":'3-dots-menu'}})],1)])]),(_vm.active == _vm.item.uid)?_c('project-dropdown',{attrs:{"item":_vm.item,"active":_vm.active},on:{"on-edit":function($event){return _vm.$emit('edit', _vm.item)},"hide-dropdown":function($event){_vm.active = -1},"deleted":_vm.project_deleted}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }