var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$route.name !== 'group-details-processing')?_c('div',{staticClass:"top-section router-project-management-top-section"},[_c('h2',{staticClass:"heading-1 is-medium-14-500-17"},[_vm._v(" "+_vm._s(_vm.localization("app-processing", "Processing"))+" ")]),_c('Metrics',{attrs:{"page":'processing'}})],1):_vm._e(),_c('div',[_c('div',{staticClass:"list-card router-process-management-main",class:{ 'padding-20': _vm.$route.name != 'group-details-processing' }},[_c('div',{staticClass:"list-top-section py-5"},[_c('div',{staticClass:"list-top-section--search"},[_c('searchbar',{on:{"search":function($event){return _vm.pageChangeHandle(1, $event)}}})],1)]),(_vm.data && _vm.data.length)?_c('div',_vm._l((_vm.data),function(item){return _c('div',{key:item.id,staticClass:"sh-list is-paddingless"},[_c('processing-list-card',{attrs:{"item":item},on:{"refresh-processing-list":_vm.getData}})],1)}),0):_c('div',[(_vm.isLoading)?_c('div',[_c('list-skeleton',{attrs:{"options":{
              row_count: 5,
              col_count: 4,
              name: 2,
              team: 1,
              user: 0,
              tags: 1,
              status: 0
            }}})],1):_c('no-results',[_c('img',{attrs:{"slot":"image","src":require("@/assets/illustrations/processing-illustration.svg"),"alt":""},slot:"image"})])],1),_c('pagination',{staticClass:"articles-list__pagination",attrs:{"default_page_size":_vm.default_page_size,"current-page":_vm.currentPage,"page-count":_vm.pageCount,"visiblePagesCount":_vm.visiblePagesCount,"totalRecords":_vm.totalRecords},on:{"nextPage":function($event){return _vm.pageChangeHandle('next')},"previousPage":function($event){return _vm.pageChangeHandle('previous')},"loadPage":_vm.pageChangeHandle}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }