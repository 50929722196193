var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"asset-item is-relative"},[_c('div',{staticClass:"asset-menu-dropdown is-absolute",staticStyle:{"z-index":"10"}},[_c('asset-menu-dropdown',{attrs:{"asset":_vm.item},on:{"disable_asset":function($event){return _vm.$emit('disable_asset', $event)},"open_modal":function($event){return _vm.$emit('open_modal', $event)},"delete_asset":function($event){return _vm.$emit('delete_asset', $event)},"transfer_ownership":function($event){return _vm.$emit('transfer_ownership', $event)},"asset_form":function($event){return _vm.$emit('asset_form', $event)}}})],1),_c('div',{staticClass:"asset--card is-pointer",on:{"click":function($event){return _vm.$emit('open_modal', _vm.item)}}},[_c('div',{staticClass:"asset--card__image"},[_c('img',{attrs:{"src":_vm.item.properties.cover_image ||
            require('@/assets/icons/png/asset-placeholder.png'),"alt":"cover_image"}})]),_c('div',{staticClass:"asset--card__icon"},[_c('figure',[(_vm.item.image)?_c('p',[_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.item.name, html: false }),expression:"{ content: item.name, html: false }"}],staticClass:"asset--card__icon--avatar is-semiBold-18-600-22 is-uppercase",attrs:{"src":_vm.item.image}})]):(_vm.item.properties)?_c('p',[(_vm.item.properties.icon)?_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.item.name, html: false }),expression:"{ content: item.name, html: false }"}],staticClass:"asset--card__icon--avatar is-semiBold-18-600-22 is-uppercase",attrs:{"src":_vm.item.properties.icon}}):_c('span',{staticClass:"asset--card__icon--avatar is-semiBold-18-600-22 is-uppercase ",style:(("background-color: " + (_vm.item.properties.color
                  ? _vm.item.properties.color
                  : _vm.hashed_color(
                      _vm.item.name,
                      _vm.item.type ? _vm.item.type : 'projectManagement'
                    ))))},[_vm._v(_vm._s(_vm.item.name.charAt(0)))])]):_vm._e()])]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.item.name, html: false }),expression:"{ content: item.name, html: false }"}],staticClass:"is-medium-16-500-19 has-primary-text is-center is-flex mt-20"},[_c('span',[_vm._v(_vm._s(_vm._f("truncate")(_vm.item.name,22)))])]),_c('div',{staticClass:"is-flex align-center is-center my-10"},[_c('span',{staticClass:"asset--card__subtext is-medium-12-500-14"},[_vm._v(_vm._s(_vm.item.asset_type ? _vm.localization( ("app-" + (_vm.item.asset_type .split(" ") .join("-") .toLowerCase())), _vm.item.asset_type ) : "*Type not found"))])]),_c('div',{},[(_vm.item.tags && _vm.item.tags.length)?_c('assigned-tags',{staticClass:"is-flex is-center",attrs:{"tags":_vm.item.tags,"write_access":false,"number_of_tags_to_display":2}}):_c('div',{staticClass:"is-flex align-center is-center no-tags-text"},[_c('sh-icon',{staticClass:"is-20x20 mr-5 has-opacity-6",attrs:{"name":'tags'}}),_c('span',{staticClass:"is-regular-14-00-17 has-opacity-6"},[_vm._v(_vm._s(_vm.localization("app-no-tags-assigned", "*No tags assigned")))])],1)],1),_c('div',{staticClass:"is-flex is-center mt-10 is-vcenter"},[_c('span',{staticClass:"has-text-light-grey is-regular-12-00-14 mr-3"},[_vm._v(_vm._s(_vm.localization("app-entity", "Entity"))+":")]),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: (_vm.item.entity && _vm.item.entity.name) || null,
          html: false
        }),expression:"{\n          content: (item.entity && item.entity.name) || null,\n          html: false\n        }"}],staticClass:"is-medium-14-500-17 has-primary-text"},[_vm._v(_vm._s(_vm._f("truncate")(_vm.item.entity && _vm.item.entity.name ? _vm.item.entity.name : "",24)))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }