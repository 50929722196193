var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"is-semiBold-24-600-106 mt-40 mb-30"},[_vm._v(" "+_vm._s(_vm.localization("app-vault", "Vault"))+" ")]),_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-half"},[_c('span',{staticClass:"ticket-detail-heading mb-5"},[_vm._v(_vm._s(_vm.localization("app-users", "Users")))]),(
          (_vm.vault.readUsers && _vm.vault.readUsers.length) ||
            (_vm.vault.writeUsers && _vm.vault.writeUsers.length)
        )?_c('div',{staticClass:"is-flex is-vcenter"},[_c('user-tag',{attrs:{"custom_class":'tiny-user-avatar ',"custom_size":24,"users":(_vm.vault.readUsers || []).concat( (_vm.vault.writeUsers || [])),"tagsCountToShow":5,"no_access":true,"is_precense_component":true},on:{"handle_click":_vm.add_vault_users}}),_c('sh-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.localization(
              'app-add-users-to-this-ticket',
              'Add users to this ticket'
            )
          }),expression:"{\n            content: localization(\n              'app-add-users-to-this-ticket',\n              'Add users to this ticket'\n            )\n          }"}],staticClass:"is-22x22 ml-5 is-pointer add-user-takenUp",attrs:{"name":'add-users-teams'},nativeOn:{"click":function($event){return _vm.add_vault_users($event)}}})],1):_c('div',{staticClass:"is-flex is-vcenter is-pointer",on:{"click":_vm.add_vault_users}},[_c('sh-icon',{staticClass:"mr-7 is-24x24",attrs:{"name":'add-users-2'}}),_c('span',{staticClass:"mr-10 is-medium-14-500-17 has-opacity-5 has-text-theme-black"},[_vm._v(_vm._s(_vm.localization("app-add-users", "Add Users")))])],1)]),_c('div',{staticClass:"column is-half "},[_c('span',{staticClass:"ticket-detail-heading is-flex is-vcenter"},[_c('span',{staticClass:"mr-5"},[_vm._v(_vm._s(_vm.localization("app-teams", "Teams")))]),_c('sh-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.localization(
              'app-tooltip-parent-team-member-access',
              'All members of the parent teams will also be given the same level of access'
            )
          }),expression:"{\n            content: localization(\n              'app-tooltip-parent-team-member-access',\n              'All members of the parent teams will also be given the same level of access'\n            )\n          }"}],staticClass:"is-14x14 is-question",attrs:{"name":'ticket-details-info'}})],1),(
          (_vm.vault.readLabels && _vm.vault.readLabels.length) ||
            (_vm.vault.writeLabels && _vm.vault.writeLabels.length)
        )?_c('div',{staticClass:"is-flex is-vcenter"},[_c('primary-tags',{attrs:{"tags":_vm.get_teams(_vm.vault),"toShowCount":3,"no_access":true,"type":'team',"no_wrap":true,"truncate_length":10},on:{"clicked_tag":_vm.add_vault_teams}}),_c('sh-icon',{staticClass:"show-on-hover is-22x22 ml-5 is-pointer add-user-takenUp",attrs:{"name":'add-users-teams'},nativeOn:{"click":function($event){return _vm.add_vault_teams($event)}}})],1):_c('div',{staticClass:"is-flex is-vcenter is-pointer",on:{"click":_vm.add_vault_teams}},[_c('span',{staticClass:"ticket-detail-heading is-flex is-vcenter"},[_c('sh-icon',{staticClass:"mr-7 is-24x24",attrs:{"name":'add-teams'}}),_c('span',{staticClass:"mr-10 is-medium-14-500-17 has-opacity-5 has-text-theme-black"},[_vm._v(_vm._s(_vm.localization("app-add-teams", "Add Teams")))])],1)])]),_c('div',{staticClass:"column is-half mb-10 is-flex is-vcenter"},[_c('span',{staticClass:"ticket-detail-heading "},[_c('span',{staticClass:"mr-5"},[_vm._v(_vm._s(_vm.localization("app-tags", "Tags")))])]),_c('tags-dropdown',{attrs:{"complete":_vm.add_vault_tags,"tags":_vm.vault.tags || [],"display_on_hover":false,"resourceId":123}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }