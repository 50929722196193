const Missions = () => import(/* webpackChunkName: "missions" */ '../missions')
const Sidebar = () =>
  import(
    /* webpackChunkName: "missions" */ '@/app/old/desktop/shared/components/sidebar.vue'
  )
const Navbar = () =>
  import(
    /* webpackChunkName: "missions" */ '@/app/old/desktop/shared/components/navbar/navbar.vue'
  )
const MissionViewer = () =>
  import(/* webpackChunkName: "missions" */ '../components/viewer')
const MissionList = () =>
  import(/* webpackChunkName: "missions" */ '../components/mission-list')
const MissionDetails = () =>
  import(
    /* webpackChunkName: "missions" */ '../components/mission-details/mission-details'
  )

const Routes = [
  {
    path: '/missions/view/:id/viewer',
    name: 'missions-viewer',
    components: {
      default: MissionViewer,
      left: Sidebar
    },
    meta: {
      title: 'Terra viewer',
      requiresAuth: true
    }
  },
  {
    path: '/missions',
    components: {
      default: Missions,
      left: Sidebar,
      top: Navbar
    },
    meta: {
      title: 'Missions Management',
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'mission-list',
        component: MissionList
      },
      {
        path: ':id/details',
        name: 'mission-details',
        component: MissionDetails
      }
    ]
  }
]

export default Routes
