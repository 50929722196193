var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return _vm.close(); }),expression:"() => close()"}],staticClass:"is-relative dropdown is-right vault-menu-dropdown",class:{ 'is-active': _vm.is_dropdown }},[_c('div',{staticClass:"dropdown-trigger",on:{"click":function($event){_vm.is_dropdown = !_vm.is_dropdown}}},[_c('a',{attrs:{"aria-haspopup":"true","aria-controls":"dropdown-menu"}},[_c('span',[_c('sh-icon',{staticClass:"is-20x20",attrs:{"name":'3-dots-menu'}})],1)])]),(_vm.is_dropdown)?_c('div',{staticClass:"dropdown-menu",attrs:{"role":"menu"}},[_c('div',{staticClass:"dropdown-content"},[_c('div',{staticClass:"vault-menu-dropdown--top"},[_c('sh-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.localization('app-rename', 'Rename'),
            html: false
          }),expression:"{\n            content: localization('app-rename', 'Rename'),\n            html: false\n          }"}],staticClass:"image is-pointer is-24x24",class:{
            'is-disabled': !(_vm.file.writeAccess || _vm.file.ancestorWriteAccess)
          },attrs:{"file":'vault_sprite',"name":'rename'},nativeOn:{"click":function($event){_vm.$emit('rename');
            _vm.is_dropdown = false;}}}),_c('sh-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.localization('app-share', 'Share'),
            html: false
          }),expression:"{\n            content: localization('app-share', 'Share'),\n            html: false\n          }"}],staticClass:"image is-pointer is-24x24",class:{
            'is-disabled': !(_vm.file.writeAccess || _vm.file.ancestorWriteAccess)
          },attrs:{"file":'vault_sprite',"name":'share-grey'},nativeOn:{"click":function($event){_vm.$emit('share');
            _vm.is_dropdown = false;}}}),_c('sh-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: !_vm.file.archive
              ? _vm.localization('app-archive', 'Archive')
              : _vm.localization('app-unarchive', 'Unarchive'),
            html: false
          }),expression:"{\n            content: !file.archive\n              ? localization('app-archive', 'Archive')\n              : localization('app-unarchive', 'Unarchive'),\n            html: false\n          }"}],staticClass:"image is-pointer is-24x24",attrs:{"file":'vault_sprite',"name":'archive'},nativeOn:{"click":function($event){_vm.$emit('archive');
            _vm.is_dropdown = false;}}}),_c('p',[_c('sh-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.localization('app-delete', 'Delete'),
              html: false
            }),expression:"{\n              content: localization('app-delete', 'Delete'),\n              html: false\n            }"}],staticClass:"image is-pointer is-24x24",class:{ 'is-disabled': !_vm.file.ownerAccess },attrs:{"file":'vault_sprite',"name":'delete'},nativeOn:{"click":function($event){_vm.$emit('delete');
              _vm.is_dropdown = false;}}})],1)],1),_c('div',{staticClass:"vault-menu-dropdown--bottom"},[_c('a',{staticClass:"dropdown-item",on:{"click":function($event){_vm.SET_INFO_TAB({
              file: _vm.file,
              type: 'file',
              active: true,
              tab: 'info'
            });
            _vm.is_dropdown = false;}}},[_c('img',{staticClass:"mr-10",attrs:{"src":require("@/assets/icons/info.svg"),"alt":""}}),_c('span',{staticClass:"is-medium-16-500-50"},[_vm._v(_vm._s(_vm.localization("app-info", "Info")))])]),_c('a',{staticClass:"dropdown-item",on:{"click":function($event){_vm.SET_INFO_TAB({
              file: _vm.file,
              type: 'file',
              active: true,
              tab: 'activity'
            });
            _vm.is_dropdown = false;}}},[_c('sh-icon',{staticClass:"image is-pointer is-24x24",attrs:{"file":'vault_sprite',"name":'view-activity'}}),_c('span',{staticClass:"is-medium-16-500-50 ml-10"},[_vm._v(_vm._s(_vm.localization("app-view-activity", "View Activity")))])],1),_c('a',{staticClass:"dropdown-item is-relative version-name",class:{ 'is-disabled': !_vm.file.ownerAccess },on:{"click":function($event){_vm.version_input = true}}},[_c('sh-icon',{staticClass:"image is-pointer is-24x24",attrs:{"file":'helper_sprite',"name":'upload-grey'}}),_c('span',{staticClass:"is-medium-16-500-50 ml-10"},[_vm._v(_vm._s(_vm.localization("app-upload-new-version", "Upload new Version")))]),(_vm.version_input)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return (_vm.version_input = false); }),expression:"() => (version_input = false)"}],staticClass:"is-absolute ",class:[_vm.is_viewer_small ? 'viewer' : 'regular']},[_c('div',{staticClass:" create-new-folder  is-flex align-center"},[_c('input',{directives:[{name:"focus",rawName:"v-focus"},{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"create-new-folder-input is-medium-14-500-17",attrs:{"placeholder":_vm.localization('app-version-name', 'Version Name'),"type":"text"},domProps:{"value":(_vm.form.name)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.form.name
                    ? _vm.$emit('open_version_file_upload', _vm.form.name)
                    : ''},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}}),_c('p',{class:{ 'is-disabled': !_vm.form.name },on:{"click":function($event){_vm.form.name
                    ? _vm.$emit('open_version_file_upload', _vm.form.name)
                    : ''}}},[_c('sh-icon',{staticClass:"is-16x16 is-pointer",attrs:{"name":'ticket-show-tick'}})],1)])]):_vm._e()],1),_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.$emit('show_file_versions')}}},[_c('sh-icon',{staticClass:"image is-pointer is-24x24",attrs:{"file":'vault_sprite',"name":'upload-new-versions'}}),_c('span',{staticClass:"is-medium-16-500-50 ml-10"},[_vm._v(_vm._s(_vm.localization("app-show-versions", "Show Versions")))])],1),(_vm.$store.getters.download_files_zip)?_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.$emit('download')}}},[_c('img',{staticClass:"mr-15",attrs:{"src":require("@/assets/icons/vault/download.svg"),"alt":""}}),_c('span',{staticClass:"is-medium-16-500-50"},[_vm._v(_vm._s(_vm.localization("app-download", "Download")))])]):_vm._e(),_c('a',{staticClass:"dropdown-item is-relative move-to",on:{"click":function($event){_vm.is_active = !_vm.is_active}}},[_c('img',{staticClass:"mr-15",attrs:{"src":require("@/assets/icons/vault/vault-move-to.svg"),"alt":""}}),_c('span',{staticClass:"is-medium-16-500-50"},[_vm._v(_vm._s(_vm.localization("app-move-to", "Move To")))]),(_vm.is_active)?_c('folder-move-dropdown',{staticClass:"is-absolute",class:[_vm.is_viewer_small ? 'viewer' : 'regular'],attrs:{"hide_icon":true,"show_dropdown":_vm.is_active ? true : false},on:{"close":function($event){_vm.is_active = false},"move_folder":function($event){_vm.$emit('move', $event);
              _vm.close();}}}):_vm._e()],1),_c('a',{staticClass:"dropdown-item is-relative create-vault-ticket",on:{"click":function($event){_vm.open_create_ticket = !_vm.open_create_ticket}}},[_c('sh-icon',{staticClass:"is-24x24 mr-10",attrs:{"name":'sidebar-tickets'}}),_c('span',{staticClass:"is-medium-16-500-50"},[_vm._v(_vm._s(_vm.localization("app-create-ticket", "Create Ticket")))]),(_vm.open_create_ticket)?_c('create-new-ticket',{staticClass:"is-absolute ",class:[_vm.is_viewer_small ? 'viewer' : 'regular'],attrs:{"open_on_create":true,"element":_vm.file.element,"vault":true},on:{"close":function($event){return _vm.close()}}}):_vm._e()],1)])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }