var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-card"},[_c('router-view'),_c('div',{staticClass:"list-top-section",class:{ 'px-20': _vm.$route.name.includes('list') }},[_c('div',{staticClass:"list-top-section--search"},[_c('searchbar',{on:{"search":function($event){return _vm.pageChangeHandle(1, $event)}}})],1),_c('div',{staticClass:"list-top-section--create"},[(_vm.access('core', 'create_containers'))?_c('a',{on:{"click":function($event){return _vm.open_form_popup()}}},[_c('div',{staticClass:"sh-button sh-button--create sh-button--inverted is-semiBold-14-600-17"},[_vm._v(" "+_vm._s(_vm.localization("app-new-container", "New Container"))+" ")])]):_c('a',{on:{"click":function($event){return _vm.no_access_popup('core', 'create_containers')}}},[_c('div',{staticClass:"sh-button sh-button--create disabled-link is-semiBold-14-600-17"},[_vm._v(" "+_vm._s(_vm.localization("app-new-container", "New Container"))+" ")])])])]),(_vm.data && _vm.data.length && !_vm.isLoading)?_c('div',[_c('list-header'),_c('div',{staticClass:"sh-list"},_vm._l((_vm.data),function(item){return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return (_vm.hover == item.uid ? (_vm.hover = -1) : null); }),expression:"() => (hover == item.uid ? (hover = -1) : null)"}],key:item.uid,class:[
          _vm.$route.name == 'asset-details-containers'
            ? 'detail-items'
            : 'sh-list__item'
        ],on:{"mouseleave":function($event){_vm.hover = -1},"mouseenter":function($event){_vm.hover = item.uid}}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column",class:[
              _vm.$route.name == 'asset-details-containers' ? 'is-4' : 'is-4'
            ]},[_c('router-link',{staticClass:"has-text-dark",attrs:{"to":{ name: 'container-details', params: { id: item.uid } }}},[_c('main-details-media',{attrs:{"truncate_value":_vm.$route.name == 'asset-details-containers' ? '25' : '35',"show_tooltip":true,"info":{
                  name: item.name,
                  subtext: item.groups
                    ? ((item.groups.length) + " " + (item.groups.length > 1
                          ? _vm.localization('app-groups', 'Groups')
                          : _vm.localization('app-groups', 'Group')))
                    : '*No details found',
                  name_class:
                    'is-semiBold-16-600-19 has-primary-text name-highlights-on-hover',
                  subtext_class: 'is-medium-14-500-17 has-primary-text',
                  properties: item.properties
                }}})],1)],1),(_vm.$route.name == 'container-list')?_c('div',{staticClass:"column is-flex align-center"},[_c('primary-tag',{attrs:{"tags":item.asset ? [item.asset] : null,"hideIcon":false,"type":'asset',"truncate_length":10}})],1):_vm._e(),_c('div',{staticClass:"column is-flex align-center"},[_c('app-type-tag',{attrs:{"apps":item.app_types,"count":3,"item_id":item.uid}})],1),_c('div',{staticClass:"column is-flex is-between align-center"},[_c('div',[(item.tags && item.tags.length)?_c('div',[_c('assigned-tags',{attrs:{"tags":item.tags,"write_access":false,"number_of_tags_to_display":3}})],1):_c('div',{staticClass:"is-flex align-center"},[_c('sh-icon',{staticClass:"is-20x20 mr-5 has-opacity-6",attrs:{"name":'tags'}}),_c('span',{staticClass:"is-regular-14-00-17 has-opacity-7 has-text-light-grey"},[_vm._v(_vm._s(_vm.localization("app-no-tags-assigned", "*No tags assigned")))])],1)]),_c('div',[_c('transfer-owner',{ref:'containerList' + item.uid,refInFor:true,attrs:{"name":item.name,"service":_vm.containerService,"id":item.uid},on:{"update_user":function($event){return _vm.update_owner(item, $event)}}})],1),(!_vm.$store.getters.is_org_user)?_c('div',[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(
                  function () { return (_vm.dropdown == item.uid ? (_vm.dropdown = -1) : null); }
                ),expression:"\n                  () => (dropdown == item.uid ? (dropdown = -1) : null)\n                "}],staticClass:"asset-dropdown"},[_c('div',{staticClass:"asset-dropdown-selection",class:{
                    'is-hidden': _vm.hover != item.uid && _vm.dropdown != item.uid
                  },on:{"click":function($event){_vm.dropdown == -1 ? (_vm.dropdown = item.uid) : (_vm.dropdown = -1)}}},[_c('sh-icon',{staticClass:"is-20x20",attrs:{"name":'3-dots-menu'}})],1),(_vm.dropdown == item.uid)?_c('div',{staticClass:"asset-dropdown-content"},[_c('p',{attrs:{"tag":"p"},on:{"click":function($event){return _vm.open_form_popup(item)}}},[_c('sh-icon',{staticClass:"is-24x24 mr-10",attrs:{"name":'edit-small'}}),_c('span',{staticClass:"no-whitespace-wrap"},[_vm._v(_vm._s(_vm.localization("app-edit-container", "Edit Container")))])],1),_c('p',{on:{"click":function($event){return _vm.open_transfer_popup(item.uid)}}},[_c('sh-icon',{staticClass:"is-24x24 mr-10",attrs:{"name":'transfer-2'}}),_c('span',{staticClass:"no-whitespace-wrap"},[_vm._v(" "+_vm._s(_vm.localization( "app-transfer-ownership", "Transfer ownership" )))])],1),_c('p',{on:{"click":function($event){_vm.showDeleteModal = true;
                      _vm.currentContainer = item;}}},[_c('sh-icon',{staticClass:"is-24x24 mr-10",attrs:{"name":'delete-small'}}),_c('span',{staticClass:"no-whitespace-wrap"},[_vm._v(" "+_vm._s(_vm.localization( "app-delete-container", "Delete Container" )))])],1)]):_vm._e()])]):_vm._e()])])])}),0)],1):_c('div',[(_vm.isLoading)?_c('div',[_c('list-header'),_c('list-skeleton')],1):_c('no-results',[_c('img',{attrs:{"slot":"image","src":require("@/assets/illustrations/containers-illustration.svg"),"alt":""},slot:"image"}),_c('div',{attrs:{"slot":"button"},slot:"button"},[_c('a',{on:{"click":function($event){return _vm.open_form(_vm.ContainersFormComponent, {
              header: 'Create Container for',
              type: 'container'
            })}}},[_c('div',{staticClass:"sh-button sh-button--create"},[_vm._v(" "+_vm._s(_vm.localization("app-create-new-container", "Create New Container"))+" ")])])])])],1),_c('div',[_c('pagination',{staticClass:"articles-list__pagination",attrs:{"default_page_size":_vm.default_page_size,"current-page":_vm.currentPage,"page-count":_vm.pageCount,"visiblePagesCount":_vm.visiblePagesCount,"totalRecords":_vm.totalRecords},on:{"nextPage":function($event){return _vm.pageChangeHandle('next')},"previousPage":function($event){return _vm.pageChangeHandle('previous')},"loadPage":_vm.pageChangeHandle}})],1),(_vm.showDeleteModal)?_c('delete-modal',{attrs:{"resourceId":_vm.currentContainer.uid,"resourceName":_vm.currentContainer.name,"resourceType":'container',"resourceService":_vm.containerService},on:{"closed":function($event){_vm.showDeleteModal = false},"deleted":function($event){_vm.show_success('Resource successfully deleted');
      _vm.getData();
      _vm.$parent.$emit('update');}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }