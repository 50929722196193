var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-card"},[_c('router-view'),_c('div',{staticClass:"list-top-section",class:{ 'px-20': _vm.$route.name.includes('list') }},[_c('div',{staticClass:"list-top-section--search"},[_c('searchbar',{on:{"search":function($event){return _vm.pageChangeHandle(1, $event)}}})],1),_c('div',{staticClass:"list-top-section--create"},[(_vm.access('core', 'create_labels'))?_c('a',{on:{"click":function($event){return _vm.open_form(_vm.TeamFormComponent, { header: 'Create Team for' })}}},[_c('div',{staticClass:"sh-button sh-button--create sh-button--inverted is-semiBold-14-600-17"},[_vm._v(" "+_vm._s(_vm.localization("app-new-team", "New Team"))+" ")])]):_c('a',{on:{"click":function($event){return _vm.no_access_popup('core', 'create_labels')}}},[_c('div',{staticClass:"sh-button sh-button--create sh-button--inverted is-semiBold-14-600-17 disabled-link is-not-allowed"},[_vm._v(" "+_vm._s(_vm.localization("app-new-team", "New Team"))+" ")])])])]),(_vm.data && _vm.data.length && !_vm.isLoading)?_c('div',[_c('list-header'),_c('div',{staticClass:"sh-list"},_vm._l((_vm.data),function(item){return _c('div',{key:item.id,staticClass:"sh-list__item",on:{"mouseleave":function($event){_vm.hover = -1},"mouseenter":function($event){_vm.hover = item.uid}}},[_c('div',{staticClass:"columns is-flex align-center"},[_c('div',{staticClass:"column is-3"},[_c('router-link',{staticClass:"has-text-dark",attrs:{"to":{ name: 'team-details', params: { id: item.uid } }}},[_c('main-details-media',{attrs:{"info":{
                  name: item.name,
                  name_class:
                    'is-semiBold-16-600-19 name-highlights-on-hover',
                  subtext: item.label && item.label.name,
                  subtext_class:
                    'is-regular-14-00-17 has-text-theme-black mt-7',
                  properties: item.properties,
                  type: 'userManagement'
                }}})],1)],1),_c('div',{staticClass:"column is-3"},[_c('primary-tags',{attrs:{"tags":item.asset_id ? [_vm.get_associated_asset(item.asset_id)] : [],"type":'asset',"toShowCount":1}})],1),_c('div',{staticClass:"column is-3"},[_c('primary-tags',{attrs:{"tags":item.assets,"type":'asset',"toShowCount":1}})],1),_c('div',{staticClass:"column is-2"},[_c('user-tag',{attrs:{"no_access":_vm.no_access,"users":item.users.map(function (user) { return _vm.$store.getters.state_users[user.uid]; }),"tagsCountToShow":_vm.user_count || 3,"custom_class":'tiny-user-avatar is-medium-14-500-17',"custom_size":30}})],1),_c('div',{staticClass:"column is-2 is-flex is-between"},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(
                function () { return (_vm.dropdown == item.uid ? (_vm.dropdown = -1) : null); }
              ),expression:"\n                () => (dropdown == item.uid ? (dropdown = -1) : null)\n              "}],staticClass:"asset-dropdown "},[_c('div',{staticClass:"asset-dropdown-selection",class:{
                  'is-hidden': _vm.hover != item.uid && _vm.dropdown != item.uid
                },on:{"click":function($event){_vm.dropdown == -1 ? (_vm.dropdown = item.uid) : (_vm.dropdown = -1)}}},[_c('sh-icon',{staticClass:"is-20x20",attrs:{"name":'3-dots-menu'}})],1),(_vm.dropdown == item.uid)?_c('div',{staticClass:"asset-dropdown-content"},[_c('p',{on:{"click":function($event){return _vm.open_form(
                      _vm.TeamFormComponent,
                      { header: 'Edit Team for' },
                      item
                    )}}},[_c('sh-icon',{staticClass:"is-24x24 mr-10",attrs:{"name":'edit-small'}}),_c('span',{staticClass:"no-whitespace-wrap"},[_vm._v(_vm._s(_vm.localization("app-edit-team", "Edit Team"))+" ")])],1),_c('p',{on:{"click":function($event){_vm.showDeleteModal = true;
                    _vm.currentTeam = item;}}},[_c('sh-icon',{staticClass:"is-24x24 mr-10",attrs:{"name":'delete-small'}}),_c('span',{staticClass:"no-whitespace-wrap"},[_vm._v(_vm._s(_vm.localization("app-delete-team", "Delete Team")))])],1)]):_vm._e()])])])])}),0)],1):_c('div',[(_vm.isLoading)?_c('div',[_c('list-header'),_c('list-skeleton')],1):_c('no-results',[_c('img',{attrs:{"slot":"image","src":require("@/assets/illustrations/teams-illustration.svg"),"alt":""},slot:"image"}),_c('div',{attrs:{"slot":"button"},slot:"button"},[_c('div',{staticClass:"sh-button sh-button--create",on:{"click":function($event){return _vm.open_form(_vm.TeamFormComponent, { header: 'Create Team for' })}}},[_vm._v(" "+_vm._s(_vm.localization("app-create-new-team", "Create New Team"))+" ")])])])],1),_c('pagination',{staticClass:"articles-list__pagination",attrs:{"default_page_size":_vm.default_page_size,"current-page":_vm.currentPage,"page-count":_vm.pageCount,"visiblePagesCount":_vm.visiblePagesCount,"totalRecords":_vm.totalRecords},on:{"nextPage":function($event){return _vm.pageChangeHandle('next')},"previousPage":function($event){return _vm.pageChangeHandle('previous')},"loadPage":_vm.pageChangeHandle}}),(_vm.showDeleteModal)?_c('delete-modal',{attrs:{"resourceId":_vm.currentTeam.uid,"resourceName":_vm.currentTeam.name,"resourceType":'team',"resourceService":_vm.teamService},on:{"closed":function($event){_vm.showDeleteModal = false},"deleted":function($event){_vm.show_success('Resource successfully deleted');
      _vm.getData();}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }