var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.user.status == 'invited')?_c('div',{on:{"click":_vm.resend_invite_email}},[_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.localization('app-resent-invite', 'Resend Invite'),
        html: false
      }),expression:"{\n        content: localization('app-resent-invite', 'Resend Invite'),\n        html: false\n      }"}],staticClass:"user-dropdown__resend",attrs:{"src":require("@/assets/icons/details/resend-mail.svg")}})]):_c('div',{staticClass:"user-dropdown"},[_c('img',{staticClass:"user-dropdown__ellipsis",attrs:{"src":require("@/assets/icons/details/ellipsis-v.svg")},on:{"click":function($event){_vm.openDropdown = !_vm.openDropdown}}}),(_vm.openDropdown)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.clicked_outside_dropdown),expression:"clicked_outside_dropdown"}],staticClass:"user-dropdown__dropdown"},[(!_vm.is_guest)?_c('div',[_c('div',{staticClass:"user-dropdown__dropdown__heading"},[_vm._v("Password")]),_c('div',{staticClass:"user-dropdown__dropdown__item user-dropdown__dropdown__item--active",on:{"click":function($event){_vm.setPassword = true;
            _vm.openDropdown = false;}}},[_c('img',{staticClass:"mr-10",attrs:{"src":require("@/assets/icons/details/set-password.svg")}}),_c('span',[_vm._v("Set Password")])]),_c('div',{staticClass:"user-dropdown__dropdown__item user-dropdown__dropdown__item--active",on:{"click":function($event){_vm.resetPassword = true;
            _vm.openDropdown = false;}}},[_c('img',{staticClass:"mr-10",attrs:{"src":require("@/assets/icons/details/reset-password.svg")}}),_c('span',[_vm._v("Reset Password")])]),_c('div',{staticClass:"user-dropdown__dropdown__item user-dropdown__dropdown__item--active",on:{"click":function($event){_vm.expirePassword = true;
            _vm.openDropdown = false;}}},[_c('img',{staticClass:"mr-10",attrs:{"src":require("@/assets/icons/details/expire-password.svg")}}),_c('span',[_vm._v("Expire Password")])])]):_vm._e(),_c('div',{staticClass:"user-dropdown__dropdown__heading"},[_vm._v(" "+_vm._s(_vm.localization("app-status", "Status"))+" ")]),(!_vm.is_guest)?_c('div',{staticClass:"user-dropdown__dropdown__item",class:[
          _vm.canPromote
            ? 'user-dropdown__dropdown__item--active'
            : 'user-dropdown__dropdown__item--disabled'
        ],on:{"click":_vm.promote_to_manager}},[_c('img',{staticClass:"mr-10",attrs:{"src":require("@/assets/icons/details/promote-manager.svg")}}),_c('span',[_vm._v("Promote Manager")])]):_vm._e(),(!_vm.is_guest)?_c('div',{staticClass:"user-dropdown__dropdown__item",class:[
          _vm.canDemote
            ? 'user-dropdown__dropdown__item--active'
            : 'user-dropdown__dropdown__item--disabled'
        ],on:{"click":_vm.demote_manager}},[_c('img',{staticClass:"mr-10",attrs:{"src":require("@/assets/icons/details/demote-manager.svg")}}),_c('span',[_vm._v("Demote Manager")])]):_vm._e(),_c('div',{staticClass:"user-dropdown__dropdown__item",class:[
          _vm.canActivate
            ? 'user-dropdown__dropdown__item--active'
            : 'user-dropdown__dropdown__item--disabled'
        ],on:{"click":_vm.activate_user}},[_c('img',{staticClass:"mr-10",attrs:{"src":require("@/assets/icons/details/activate.svg")}}),_c('span',[_vm._v("Activate")])]),(!_vm.is_guest)?_c('div',{staticClass:"user-dropdown__dropdown__item",class:[
          _vm.canSuspend
            ? 'user-dropdown__dropdown__item--active'
            : 'user-dropdown__dropdown__item--disabled'
        ],on:{"click":_vm.suspend_user}},[_c('img',{staticClass:"mr-10",attrs:{"src":require("@/assets/icons/details/suspend.svg")}}),_c('span',[_vm._v("Suspend")])]):_vm._e(),_c('div',{staticClass:"user-dropdown__dropdown__item",class:[
          _vm.canDeactivate
            ? 'user-dropdown__dropdown__item--active'
            : 'user-dropdown__dropdown__item--disabled'
        ],on:{"click":_vm.deactivate_user}},[_c('img',{staticClass:"mr-10",attrs:{"src":require("@/assets/icons/details/deactivate.svg")}}),_c('span',[_vm._v("Deactivate")])])]):_vm._e(),(_vm.setPassword)?_c('set-password',{on:{"closed":function($event){_vm.setPassword = false}}}):_vm._e(),(_vm.resetPassword)?_c('reset-password',{on:{"closed":function($event){_vm.resetPassword = false}}}):_vm._e(),(_vm.expirePassword)?_c('expire-password',{on:{"closed":function($event){_vm.expirePassword = false}}}):_vm._e(),(_vm.promoteManager)?_c('promote-manager',{attrs:{"user":_vm.user},on:{"closed":function($event){_vm.promoteManager = false}}}):_vm._e(),(_vm.demoteManager)?_c('demote-manager',{attrs:{"user":_vm.user},on:{"closed":function($event){_vm.demoteManager = false}}}):_vm._e(),(_vm.activate)?_c('activate-user',{attrs:{"user":_vm.user,"is_guest":_vm.is_guest},on:{"closed":function($event){_vm.activate = false}}}):_vm._e(),(_vm.suspend)?_c('suspend-user',{on:{"closed":function($event){_vm.suspend = false}}}):_vm._e(),(_vm.deactivate)?_c('deactivate-user',{attrs:{"is_guest":_vm.is_guest},on:{"closed":function($event){_vm.deactivate = false}}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }