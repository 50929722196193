var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{on:{"dragenter":function($event){$event.preventDefault();$event.stopPropagation();_vm.current_folder_uid ? _vm.open_file_upload() : ''}}},[(_vm.current_folder_uid)?_c('upload-file',{attrs:{"service":"vault","name":_vm.current_folder ? _vm.current_folder.name : null,"type":"folder","uid":_vm.current_folder ? _vm.current_folder.uid : null,"id":"vault_folders_modal","uploadMethod":_vm.uploadMethod,"hasCompressor":false,"isModal":"true"},on:{"uploaded_success":_vm.upload_completed,"initialised":function($event){_vm.uppy = $event}}},[_c('div',{staticStyle:{"display":"none"}},[_vm._v("click")])]):_vm._e(),(_vm.current_folder_uid)?_c('div',{staticClass:"is-flex is-between align-center ml-20 mb-10",class:{
      'mb-20': !_vm.is_viewer_small,
      'is-hidden': _vm.info_tab.active && _vm.is_viewer_small
    }},[_c('div',{staticClass:"is-inline-flex align-center"},[_c('sh-icon',{staticClass:"is-16x16 mr-10 is-pointer",attrs:{"name":'ticket-header-back'},nativeOn:{"click":function($event){_vm.$route.name === 'vault'
            ? _vm.$router.push({
                name: 'vault',
                query: { asset: _vm.$route.query.asset }
              })
            : _vm.$store.commit('vault/SET_CURRENT_FOLDER_UID', null)}}}),(!_vm.is_viewer_small)?_vm._l((_vm.breadcrumb),function(crumb,i){return _c('p',{key:crumb.uid,staticClass:"is-medium-16-500-19 mr-5 is-pointer is-capitalized"},[(_vm.$route.name == 'vault')?_c('router-link',{staticStyle:{"color":"#171e48"},attrs:{"to":{
              params: { folder_id: crumb.uid },
              query: { asset: _vm.$route.query.asset }
            }}},[_vm._v(_vm._s(crumb.name))]):_c('a',{on:{"click":function($event){return _vm.$store.commit('vault/SET_CURRENT_FOLDER_UID', crumb.uid)}}},[_vm._v(_vm._s(_vm._f("truncate")(crumb.name,_vm.is_viewer_small ? 5 : 20)))]),(i != _vm.breadcrumb.length - 1)?_c('span',{staticClass:"ml-5"},[_vm._v(">")]):_vm._e()],1)}):_c('p',{staticClass:"is-medium-14-500-17 is-pointer"},[_vm._v(" "+_vm._s(_vm.localization("app-home", "Home"))+" ")])],2),(_vm.$route.name.includes('viewer'))?_c('svg',{staticClass:"image is-24x24 mr-10 mt-5 is-pointer is-block is-pulled-right",attrs:{"viewBox":"0 0 100 100"},on:{"click":function($event){_vm.current_folder && _vm.current_folder.uid
          ? _vm.open_file_upload()
          : _vm.$eventHub.$emit('create_folder', { parent: null })}}},[_c('use',{attrs:{"xlink:href":require("@/assets/icons/helper_sprite.svg") + "#upload-grey"}})]):_vm._e()]):_vm._e(),_c('div',{staticClass:"vault__list columns is-gapless is-marginless"},[((!_vm.info_tab.active && _vm.is_viewer_small) || !_vm.is_viewer_small)?_c('div',{staticClass:"column has-background-white"},[(!_vm.is_viewer_small)?_c('vault-headers',{directives:[{name:"show",rawName:"v-show",value:(_vm.view_type == 'list'),expression:"view_type == 'list'"}],class:{ 'vault__list-header': _vm.$route.name.includes('viewer') },attrs:{"small_viewer":_vm.is_viewer_small,"sorting_key":_vm.sort_key},on:{"update_sort_key":function($event){_vm.sort_key = $event}}}):_vm._e(),_c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadmore),expression:"loadmore"}],class:{
          'has-slimscroll-xs vault__list-content has-background-white': !_vm.$route.name.includes(
            'viewer'
          )
        }},[(_vm.group_by.key)?_c('div',[(Object.values(_vm.grouped_data).length)?_c('div',{staticClass:"is-relative"},_vm._l((_vm.group_by.key == 'accessed'
                ? _vm.recent_data
                : _vm.grouped_data),function(group,heading,index){return _c('div',{key:index},[_c('vault-group-card',{attrs:{"group":group,"index":index,"is_loading":_vm.is_loading,"heading":heading,"is_viewer_small":_vm.is_viewer_small}})],1)}),0):(_vm.is_loading)?_c('vault-list-skeleton'):_c('vault-noresults')],1):_c('div',[_c('div',{staticClass:"is-relative"},[_c('data-component',{attrs:{"data":(_vm.sorted_folders || []).concat( (_vm.sorted_files || [])),"is_loading":_vm.is_loading,"is_viewer_small":_vm.is_viewer_small}})],1)])])],1):_vm._e(),_c('tabs',{staticClass:"column is-3",class:{
        'is-12': _vm.info_tab.active && _vm.is_viewer_small,
        'is-hidden': !_vm.info_tab.active
      },attrs:{"is_viewer_small":_vm.is_viewer_small}})],1),(_vm.current_folder_uid)?_c('div',{class:{
      'is-disabled':
        _vm.current_folder &&
        !(_vm.current_folder.writeAccess || _vm.current_folder.ancestorWriteAccess)
    }},[_c('div',{staticClass:"vault-add-files-widget has-background-white is-flex align-center is-center",class:{ 'is-hidden': _vm.$route.name.includes('viewer') }},[_c('svg',{staticClass:"image is-24x24 mr-5 mt-5",attrs:{"viewBox":"0 0 100 100"}},[_c('use',{attrs:{"xlink:href":require("@/assets/icons/helper_sprite.svg") + "#upload-grey"}})]),_vm._v(" "+_vm._s(_vm.localization( "app-drop-files-here-to-attach-or-browse", "Drop files here to attach or" ))+" "),_c('span',{staticClass:"ml-5 has-text-blue has-text-weight-medium text-underlined is-pointer",attrs:{"id":"upload-browse"},on:{"click":function($event){_vm.current_folder && _vm.current_folder.uid
            ? _vm.open_file_upload()
            : _vm.$eventHub.$emit('create_folder', { parent: null })}}},[_vm._v(_vm._s(_vm.localization("app-browse", "browse")))])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }