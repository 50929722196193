import axios from "axios";
import store from "@/app/old/store/";
import { authService } from "@/app/old/desktop/shared/services/";
import { apiBaseUrl } from "@/environment/environment";
import { get_cookies_host_name } from "@/utils/helperFun";

/**
 * Axios basic configuration
 * Some general configuration can be added like timeout, headers, params etc. More details can be found on https://github.com/axios/axios
 * */
const config = {
  baseURL: apiBaseUrl
};

let add_slash = (original_url, base_url) => {
  let check_url;

  if (original_url && original_url.includes("https://"))
    check_url = original_url;
  else check_url = base_url || "";

  if (
    !check_url.includes("server.sensehawk.com") &&
    !check_url.includes("ril.solar")
  )
    return check_url;
  const [url, params] = original_url.split("?");

  if (!url.endsWith("/")) {
    return `${url}/${params ? "?" + params : ""}`;
  }

  return original_url;
};
/**
 * Creating the instance of Axios
 * It is because, in large scale application we may need to consume APIs from more than single server,
 * So, may need to create multiple http client with different config
 * Only this client will be used rather than axios in the application
 **/
const httpClient = axios.create(config);

/**
 * Auth interceptors
 * @description Configuration related to AUTH token can be done in interceptors.
 * Currenlty it is just doing nothing but idea to to show the capability of axios and its interceptors
 * In future, interceptors can be created into separate files and consumed into multiple http clients
 * @param {*} config
 */
const authInterceptor = config => {
  /** add auth token */
  if (!config.headers) config.headers = {};
  let token = window.$cookies.get("access_token");
  if (token) config.headers["authorization"] = "jwt " + token;
  if (config.url.includes("no_token")) {
    delete config.headers["authorization"];
  }

  if (
    !(config.url.includes("no_org") || config.url.includes("organization")) &&
    store.state.current_organization
  )
    config.url += `${config.url.includes("?") ? "&" : "?"}organization=${
      store.state.current_organization.uid
    }`;

  config.url = add_slash(config.url, config.baseURL);
  return config;
};

const loggerInterceptor = config => {
  /** Add logging here */
  return config;
};

/** Adding the request interceptors */
httpClient.interceptors.request.use(authInterceptor);
httpClient.interceptors.request.use(loggerInterceptor);

/** Adding the response interceptors */
httpClient.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalRequest = error.config;
    // console.log(error, error.response)
    if (
      error &&
      error.response &&
      error.response.status === 401 &&
      originalRequest.url.includes("access-code-auth")
    ) {
      window.$cookies.remove("access_token", null, get_cookies_host_name());
      window.location.href = location.origin + "/auth";
      return Promise.reject(error);
    }
    if (
      error &&
      error.response &&
      error.response.data.code === "OAUTH_21" &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      return authService.get_refresh_token().then(res => {
        if (!res.access_token) return;
        window.$cookies.set(
          "access_token",
          res.access_token,
          "1y",
          "/",
          get_cookies_host_name()
        );
        if (res.access_token)
          originalRequest.headers["authorization"] = "jwt " + res.access_token;
        return axios(originalRequest);
      });
    } else return Promise.reject(error);
  }
);

export { httpClient };
