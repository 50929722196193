var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return (_vm.active ? (_vm.active = false) : null); }),expression:"() => (active ? (active = false) : null)"}],staticClass:"is-sh-dropdown width"},[_c('div',{staticClass:"sh-dropdown-selection is-pointer"},[(_vm.custom_icon && _vm.custom_icon.name && !_vm.watcher_uids.length)?_c('sh-icon',{staticClass:"is-pointer is-24x24",attrs:{"file":_vm.custom_icon.file,"name":_vm.custom_icon.name},nativeOn:{"click":function($event){_vm.active = !_vm.active}}}):_c('sh-icon',{staticClass:"is-24x24 ml-10",attrs:{"name":'add-users-teams'},nativeOn:{"click":function($event){_vm.active = !_vm.active}}})],1),(_vm.active)?_c('div',{staticClass:"is-options-list",class:{
        'is-viewer-right': ['terra-viewer', 'therm-viewer'].includes(
          _vm.$route.name
        )
      },staticStyle:{"border-radius":"10px","width":"19rem","overflow":"hidden"}},[_c('div',{staticClass:"field px-10 py-5 has-background-white is-marginless"},[_c('p',{staticClass:"control has-icons-left has-icons-right"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchQuery),expression:"searchQuery"}],staticClass:"input pt-10",attrs:{"type":"email","placeholder":_vm.localization('app-search', 'Search')},domProps:{"value":(_vm.searchQuery)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchQuery=$event.target.value}}}),_c('span',{staticClass:"icon is-small is-left"},[_c('sh-icon',{staticClass:"is-16x16",attrs:{"name":'search'}})],1)])]),_c('ul',{staticClass:"has-slimscroll-xs",staticStyle:{"height":"20rem","overflow":"auto"}},[_vm._m(0),(_vm.filtered_teams.length == 0)?_c('div',{staticClass:"is-flex my-30 is-center"},[_vm._v(" No search found ")]):_vm._l((_vm.filtered_teams),function(team){return _c('li',{key:team.uid,staticClass:"item is-vcenter",on:{"click":function($event){return _vm.update_share_list(team)}}},[_c('div',{staticClass:"item--icon is-relative"},[_c('div',{staticClass:"tiny-user-avatar",style:(("background-color: " + (team && team.properties && team.properties.color
                    ? team.properties.color
                    : _vm.hashed_color(
                        team ? team.name : team.uid,
                        'userManagement'
                      ))))},[_vm._v(" "+_vm._s(team ? team.name.charAt(0).toUpperCase() : team.uid.charAt(0).toUpperCase())+" ")]),(_vm.watcher_uids.includes(team.uid))?_c('sh-icon',{staticClass:"is-16x16 watcher_selected",attrs:{"name":'tick-blue-bg'}}):_vm._e()],1),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: team.name && team.name.length > 12 ? team.name : null,
              html: false
            }),expression:"{\n              content: team.name && team.name.length > 12 ? team.name : null,\n              html: false\n            }"}],staticClass:"sh-dropdown-content__item--text item--text username-truncate"},[_vm._v(_vm._s(_vm._f("truncate")(team.name,12)))])])})],2)]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"item mobile-header"},[_c('p',{staticClass:"is-medium-16-500-19"},[_vm._v("Select Teams")])])}]

export { render, staticRenderFns }