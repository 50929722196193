var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return (_vm.active = false); }),expression:"() => (active = false)"}],staticClass:"is-sh-dropdown is-assignee ",class:{ 'has-no-width': _vm.is_name_hidden }},[_c('div',{class:[
      [_vm.active ? 'is-open' : 'is-close'],
      [_vm.is_name_hidden ? 'is-flex is-vcenter' : 'is-selector'],
      [
        _vm.has_dark_theme && _vm.active
          ? 'black-background'
          : _vm.has_dark_theme
          ? 'dark-theme-colors'
          : null
      ]
    ],style:([!_vm.isMobile && !_vm.active ? { 'max-width': (_vm.width + "rem") } : null])},[_c('span',{staticClass:"is-pointer",on:{"click":function($event){_vm.active = !_vm.active}}},[(_vm.existing_user && _vm.existing_user.uid)?_c('div',{staticClass:"is-flex is-vcenter"},[_c('p',{staticClass:"is-flex is-vcenter",class:[_vm.align_right ? 'is-end' : null],style:({ width: ((_vm.width - 5) + "rem") })},[_c('user-avatar',{attrs:{"user":_vm.existing_user,"pxsize":_vm.pxsize,"custom_class":'tiny-user-avatar',"show_tooltip":true}}),(!_vm.is_name_hidden)?_c('span',{directives:[{name:"username",rawName:"v-username",value:(_vm.existing_user),expression:"existing_user"}],staticClass:"ml-6 username-truncate",style:([!_vm.align_right ? { width: ((_vm.width - 7) + "rem") } : null])}):_vm._e()],1),((_vm.existing_user && _vm.existing_user.uid) || _vm.can_unassign)?_c('sh-icon',{staticClass:"is-8x8 mx-5 close-icon is-pointer",class:{ 'is-hidden': _vm.hide_close },attrs:{"name":'ticket-priority-close'},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.update_user(null)}}}):_vm._e()],1):_c('div',{staticClass:"is-flex is-vcenter is-pointer"},[(_vm.icon && _vm.icon.length)?_c('sh-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.localization('app-assign-user', 'Assign user'),
            html: true
          }),expression:"{\n            content: localization('app-assign-user', 'Assign user'),\n            html: true\n          }"}],staticClass:"mr-7",class:[
            { invert_colors: _vm.has_dark_theme },
            [_vm.pxsize ? ("is-" + _vm.pxsize + "x" + _vm.pxsize) : 'is-30x30']
          ],attrs:{"name":_vm.icon}}):_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.localization('app-assign-user', 'Assign user'),
            html: true
          }),expression:"{\n            content: localization('app-assign-user', 'Assign user'),\n            html: true\n          }"}],staticClass:"mr-7",class:[
            { invert_colors: _vm.has_dark_theme },
            [_vm.pxsize ? ("is-" + _vm.pxsize + "x" + _vm.pxsize) : 'is-30x30']
          ],attrs:{"src":require("@/assets/svg/add-assignee.svg"),"alt":""}}),(!_vm.is_name_hidden)?_c('span',{staticClass:"mr-10 is-medium-14-500-17 has-opacity-5",class:[
            _vm.has_dark_theme ? 'has-text-white' : ' has-text-theme-black'
          ]},[_vm._v(_vm._s(_vm.localization("app-add-assignee", "Add Assignee")))]):_vm._e()],1)])]),(_vm.active)?_c('div',{class:{ 'mobile-background-new': _vm.isMobile },on:{"click":function($event){_vm.active = !_vm.active}}}):_vm._e(),(_vm.active)?_c('ul',{staticClass:"is-options-list has-slimscroll-xs ",class:[
      { 'mobile-view-option': _vm.isMobile },
      { 'dark-theme-colors': _vm.has_dark_theme }
    ]},[_c('li',{staticClass:"item mobile-header"},[_c('p',{staticClass:"is-medium-16-500-19"},[_vm._v(" "+_vm._s(_vm.localization("app-add-assignee", "Add Assignee"))+" ")]),_c('p',{on:{"click":function($event){_vm.active = false}}},[(_vm.active)?_c('sh-icon',{staticClass:"is-12x12",attrs:{"name":'close'}}):_vm._e()],1)]),_c('div',{staticClass:"field is-marginless"},[_c('p',{staticClass:"control has-icons-left has-icons-right "},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchQuery),expression:"searchQuery"}],staticClass:"input",class:{ 'dark-theme-colors': _vm.has_dark_theme },attrs:{"type":"email","placeholder":_vm.localization('app-search', 'Search')},domProps:{"value":(_vm.searchQuery)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchQuery=$event.target.value}}}),_c('span',{staticClass:"icon is-left is-large"},[_c('sh-icon',{attrs:{"name":'search'}})],1)])]),(_vm.is_loading && !_vm.users)?_c('div',[_c('img',{staticClass:"image is-64x64 ml-auto mr-auto",attrs:{"src":require("@/assets/icons/ellipsis-animation.svg")}})]):_vm._e(),(_vm.users.length)?_c('div',{class:{ 'dark-theme-colors': _vm.has_dark_theme }},_vm._l((_vm.users),function(user){return _c('li',{key:user.uid,staticClass:"item is-vcenter",class:{ 'dark-theme-colors': _vm.has_dark_theme },on:{"click":function($event){$event.stopPropagation();return _vm.update_user(user)}}},[(user)?_c('div',[_c('user-avatar',{attrs:{"user":user,"pxsize":24,"custom_class":[
              _vm.has_dark_theme
                ? 'tiny-user-avatar mr-5 '
                : 'tiny-user-avatar mr-5'
            ]}})],1):_vm._e(),_c('span',{directives:[{name:"username",rawName:"v-username",value:(user),expression:"user"}],staticClass:"item--text",class:[
            { 'has-text-light-grey': _vm.has_dark_theme },
            { 'username-truncate': !_vm.isMobile }
          ],style:({ width: ((_vm.width - 5) + "rem") })})])}),0):(_vm.searchQuery.length)?_c('div',{staticClass:"mb-10"},[_c('invite-user',{class:{ invert_colors: _vm.has_dark_theme },attrs:{"search":_vm.searchQuery},on:{"invited":function($event){return _vm.update_user($event)}}})],1):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }