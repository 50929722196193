var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"terra-card"},[_vm._m(0),(_vm.is_group_loading)?_c('div',[_c('terra-group-skeleton')],1):_c('div',[(_vm.details)?_c('div',{staticClass:"terra-card__body has-slimscroll-xs scrollbox card-height-750"},[_c('div',{staticClass:"terra-card__body--card scrollbox-content"},_vm._l((_vm.sort(_vm.details.groups)),function(group){return _c('div',{key:group.uid,staticClass:"list-card",class:{ 'is-active': _vm.active_group.uid == group.uid }},[_c('div',{staticClass:"list-card__header",class:{
              'active_color is-semiBold-16-600-19':
                _vm.active_group.uid == group.uid
            },on:{"click":function($event){_vm.active_group.uid != group.uid
                ? (_vm.active_group = group)
                : (_vm.active_group = {})}}},[_c('span',{staticClass:"list-card__header--text"},[_vm._v(_vm._s(group.name))]),_c('span',{staticClass:"list-card__header--icon is-pointer"},[_c('i',{staticClass:"fas",class:[
                  _vm.active_group.uid == group.uid
                    ? 'fa-chevron-up active_color'
                    : 'fa-chevron-down '
                ]})])]),(_vm.active_group.uid == group.uid)?_c('project',{attrs:{"group":group,"active_project":_vm.active_project},on:{"select-active-project":function($event){_vm.active_project = $event}}}):_vm._e()],1)}),0)]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"terra-card__header is-medium-18-500-22 is-flex align-center"},[_c('p',{},[_vm._v("Raster")])])}]

export { render, staticRenderFns }