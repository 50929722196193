var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar has-slimscroll-xs is-paddingless",class:{ 'is-thinner': _vm.shrink_sidebar || _vm.is_tablet }},[_c('div',{staticClass:"sidebar--standard has-slimscroll",class:{ 'is-thinner pt-50': _vm.shrink_sidebar || _vm.is_tablet }},[_c('div',{staticClass:"new-logo"},[(!_vm.shrink_sidebar)?_c('img',{staticClass:"is-marginless is-logo",attrs:{"src":_vm.$store.state.current_organization &&
          _vm.$store.state.current_organization.logo
            ? _vm.$store.state.current_organization.logo
            : require('@/assets/logos/sensehawk-logo.svg'),"alt":""}}):(_vm.shrink_sidebar || _vm.is_tablet)?_c('div',{staticClass:"image is-32x32 margin-x-auto",staticStyle:{"margin-bottom":"3.6rem !important"}},[_c('img',{staticClass:"is-marginless",attrs:{"src":_vm.$store.state.current_organization &&
            _vm.$store.state.current_organization.logo_small
              ? _vm.$store.state.current_organization.logo_small
              : require('@/assets/logos/sensehawk-logo-small.svg'),"alt":""}})]):_c('img',{staticClass:"is-marginless is-logo--mobile",attrs:{"src":_vm.$store.state.current_organization &&
          _vm.$store.state.current_organization.logo_small
            ? _vm.$store.state.current_organization.logo_small
            : require('@/assets/logos/sensehawk-logo-small.svg'),"alt":""}})]),_vm._l((_vm.navigation_headers),function(nav){return _c('div',{key:nav.header,staticClass:"sidebar__item is-pointer",on:{"click":function($event){return _vm.navigate(nav)}}},[_c('p',{staticClass:"sidebar__item--image"},[_c('sh-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.shrink_sidebar
              ? _vm.localization(
                  ("app-" + (nav.header
                    .split(' ')
                    .join('-')
                    .toLowerCase())),
                  nav.header
                )
              : null,
            classes: [],
            placement: 'bottom-end'
          }),expression:"{\n            content: shrink_sidebar\n              ? localization(\n                  `app-${nav.header\n                    .split(' ')\n                    .join('-')\n                    .toLowerCase()}`,\n                  nav.header\n                )\n              : null,\n            classes: [],\n            placement: 'bottom-end'\n          }"}],staticClass:"icon is-20x20",attrs:{"name":_vm.handle_routes(nav.header) ? nav.sprite + '-active' : nav.sprite}})],1),(!_vm.shrink_sidebar && !_vm.is_tablet)?_c('span',{staticClass:"sidebar__item--text is-medium-15-500-18",class:{ 'has-text-white': _vm.handle_routes(nav.header) }},[_vm._v(_vm._s(_vm.localization( ("app-" + (nav.header .split(" ") .join("-") .toLowerCase())), nav.header )))]):_vm._e()])})],2),(false)?_c('div',{staticClass:"sidebar--pinned"},[_c('div',{staticClass:"level",on:{"click":function($event){_vm.togglePinnedMenu = !_vm.togglePinnedMenu}}},[_c('p',{staticClass:"is-pinned level-left"},[_vm._v(" "+_vm._s(_vm.localization("app-pinned", "Pinned"))+" ")]),_c('p',{staticClass:"is-pinned level-right"},[_c('i',{staticClass:"fas fa-angle-down",class:{ 'is-hidden': _vm.togglePinnedMenu }}),_c('i',{staticClass:"fas fa-angle-up",class:{ 'is-hidden': !_vm.togglePinnedMenu }})])]),_vm._l((_vm.PINNED_TABS),function(tab,i){return _c('li',{key:i,staticClass:"sidebar__item ml-20",class:{ 'is-hidden': _vm.togglePinnedMenu }},[(!_vm.shrink_sidebar && !_vm.is_tablet)?_c('router-link',{staticClass:"sidebar__item--text is-medium-15-500-18",attrs:{"to":{ path: tab.path },"tag":"span"}},[_vm._v(_vm._s(tab.name))]):_vm._e()],1)})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }