var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('skeleton-loading',{staticClass:"sh-skeleton"},_vm._l((3),function(item){return _c('row',{key:item,attrs:{"gutter":{ top: '30px' }}},[_c('column',{attrs:{"span":6}},[_c('square-skeleton',{attrs:{"boxProperties":{
            top: '5px',
            width: '80%',
            height: '10px'
          }}}),_c('square-skeleton',{attrs:{"boxProperties":{
            top: '8px',
            width: '75%',
            height: '8px'
          }}})],1),_c('column',{attrs:{"span":4}},[_c('square-skeleton',{attrs:{"boxProperties":{
            top: '5px',
            width: '80%',
            height: '10px'
          }}})],1),_c('column',{attrs:{"span":6}},[_c('square-skeleton',{attrs:{"boxProperties":{
            top: '5px',
            width: '90%',
            height: '10px'
          }}})],1),_c('column',{attrs:{"span":4}},[_c('square-skeleton',{attrs:{"boxProperties":{
            top: '5px',
            width: '95%',
            height: '10px'
          }}})],1),_c('column',{attrs:{"span":4}},[_c('square-skeleton',{attrs:{"boxProperties":{
            top: '5px',
            width: '95%',
            height: '10px'
          }}})],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }