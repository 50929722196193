var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('skeleton-loading',{staticClass:"sh-skeleton"},[_c('row',{attrs:{"gutter":{ top: '40px' }}},_vm._l((4),function(item){return _c('column',{key:item,attrs:{"span":6,"gutter":{ top: '30px' }}},[_c('row',[_c('column',{attrs:{"span":3}}),_c('column',{attrs:{"span":20}},[_c('square-skeleton',{attrs:{"boxProperties":{
                top: '5px',
                width: '100%',
                height: '10px'
              }}}),_c('square-skeleton',{attrs:{"boxProperties":{
                top: '10px',
                width: '90%',
                height: '8px'
              }}})],1)],1)],1)}),1),_c('row',{attrs:{"gutter":{ top: '50px' }}},_vm._l((2),function(item){return _c('column',{key:item,attrs:{"span":6,"gutter":{ top: '30px' }}},[_c('row',[_c('column',{attrs:{"span":3}}),_c('column',{attrs:{"span":20}},[_c('square-skeleton',{attrs:{"boxProperties":{
                top: '5px',
                width: '100%',
                height: '10px'
              }}}),_c('square-skeleton',{attrs:{"boxProperties":{
                top: '10px',
                width: '90%',
                height: '8px'
              }}})],1)],1)],1)}),1),_c('row',{attrs:{"gutter":{ top: '40px' }}},[_c('column',{attrs:{"span":24}},[_c('hr',{staticClass:"divider"})])],1),_c('row',{attrs:{"gutter":{ top: '35px' }}},[_c('column',{attrs:{"span":1}}),_c('column',{attrs:{"span":6}},[_c('square-skeleton',{attrs:{"boxProperties":{
            top: '5px',
            width: '100%',
            height: '10px'
          }}})],1)],1),_c('row',{attrs:{"gutter":{ top: '10px' }}},[_c('column',{attrs:{"span":1}}),_vm._l((3),function(item){return _c('column',{key:item,attrs:{"span":3}},[_c('square-skeleton',{attrs:{"boxProperties":{
            top: '5px',
            width: '90%',
            height: '60px'
          }}})],1)})],2),_c('row',{attrs:{"gutter":{ top: '55px' }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }