var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"choose-apps card"},[_c('div',{staticClass:"card-header padding-10",staticStyle:{"box-shadow":"none"}},[_c('div',{staticClass:"card-header-title is-flex is-between align-center is-capitalized is-medium-18-500-22"},[_vm._v(" "+_vm._s(_vm.asset.name)+" "),_c('i',{staticClass:"fa fa-times is-pulled-right mt-5 is-pointer",attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.$emit('close')}}})])]),_c('div',{staticClass:"card-content mx-10"},[_c('div',{staticClass:"choose-apps--lists"},[(_vm.therm.length)?_c('div',{staticClass:"card"},[_c('header',{staticClass:"card-header py-5"},[_c('a',{staticClass:"card-header-icon",on:{"click":function($event){_vm.therm_active = !_vm.therm_active}}},[_c('sh-icon',{staticClass:"is-20x20",attrs:{"name":_vm.therm_active ? 'expand-active' : 'collapse-active'}})],1),_c('p',{staticClass:"card-header-title no-padding-left is-capitalized is-medium-18-500-22"},[_vm._v(" "+_vm._s(_vm.localization("app-thermography", "thermography"))+" ("+_vm._s(_vm.filtered_therm.length)+") ")]),_c('div',{staticClass:" is-pointer is-pulled-right is-flex align-center pr-10 is-medium-14-500-17",on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
                name: 'therm-view-list',
                query: { asset: _vm.asset.uid, name: _vm.asset.name }
              })}}},[_c('sh-icon',{staticClass:"is-18x18 mr-5",attrs:{"name":'therm',"file":"helper_sprite"}}),_vm._v(_vm._s(_vm.localization("app-therm", "Therm"))+" ")],1)]),(_vm.therm_active)?_c('div',{staticClass:"card-content no-padding-top no-margin-bottom mt-5"},[_c('searchbar',{on:{"search":function($event){_vm.search_therm = $event}}}),_c('hr'),_c('div',{staticClass:" columns is-regular-12-00-14 has-text-grey pt-5 is-marginless"},[_c('div',{staticClass:"column is-5"},[_vm._v(" "+_vm._s(_vm.localization("app-therm", "Therm"))+" ")]),_c('div',{staticClass:"column is-2 "},[_vm._v(" "+_vm._s(_vm.localization("app-therm-affected", "Therm Affected"))+" ")]),_c('div',{staticClass:"column is-2 "},[_vm._v(" "+_vm._s(_vm.localization("app-total-dc-capacity", "Total DC Capacity"))+" ")]),_c('div',{staticClass:"column is-2 "},[_vm._v(" "+_vm._s(_vm.localization("app-issues", "Issues"))+" ")])]),(_vm.filtered_therm.length)?_c('div',{staticClass:"choose-apps--therm has-slimscroll-xs"},_vm._l((_vm.filtered_therm),function(item){return _c('div',{key:item.uid,staticClass:"sh-list__item"},[(item)?_c('div',{staticClass:"columns choose-apps--therm--item is-marginless list-card is-flex align-center"},[_c('div',{staticClass:"column is-5"},[_c('router-link',{attrs:{"to":{ name: 'therm-viewer', params: { id: item.uid } }},nativeOn:{"click":function($event){return _vm.$emit('close')}}},[_c('info-block',{attrs:{"hideImage":true,"info":{
                        name: _vm.localization(
                          ("app-" + (item.name
                            .toLowerCase()
                            .split(' ')
                            .join('-'))),
                          item.name
                        ),
                        name_class:
                          'is-semiBold-16-600-22 name-highlights-on-hover',
                        bottom: _vm.localization(
                          'app-thermal-scanning',
                          'Thermal Scanning'
                        ),
                        bottom_class:
                          ' is-regular-14-500-17 has-text-theme-grey has-opacity-7',
                        type: 'projectManagement'
                      },"truncate_value":40}})],1),(_vm.$store.getters.therm_v2)?_c('router-link',{staticClass:"tag is-info",attrs:{"to":{ name: 'therm-v2', params: { id: item.uid } }}},[_vm._v("v2.0")]):_vm._e()],1),_c('div',{staticClass:"column is-2"},[_c('h1',{staticClass:"is-medium-16-500-19 has-text-theme-black mt-5 affected"},[_vm._v(" "+_vm._s(item.uid === "jxoieYzpFA" ? 0 : ( ((item.metrics.modules.affected || 0) * (item.moduleWattPeak || 300)) / 1000 ).toFixed(1))+"KW ")])]),_c('div',{staticClass:"column is-2"},[_c('h1',{staticClass:"is-medium-16-500-19 has-text-theme-black mt-5 capacity"},[_vm._v(" "+_vm._s(item.uid === "jxoieYzpFA" ? 0 : ( (item.metrics.modules.total || 0) * ((item.moduleWattPeak || 300) / 1000000) ).toFixed(1))+"MW ")])]),_c('div',{staticClass:"column is-2"},[_c('h1',{staticClass:"is-medium-16-500-19 has-text-theme-black mt-5"},[_vm._v(" "+_vm._s((item.metrics.issues && _vm.total_issues(item.metrics.issues)) || "0")+" ")])]),_c('div',{staticClass:"column is-1 is-flex is-end actions"},[_c('router-link',{staticClass:"is-pointer pr-10",attrs:{"to":{
                      name: 'therm-view-details',
                      params: { id: item.uid }
                    }}},[_c('div',{staticClass:"is-flex align-center is-medium-14-500-17"},[_vm._v(" "+_vm._s(_vm.localization("app-dashboard", "Dashboard"))+" "),_c('sh-icon',{staticClass:" ml-5 is-15x15",attrs:{"name":'group-right-arrow'}})],1)])],1)]):_vm._e()])}),0):_c('div',{staticClass:"m-no-terra"},[_vm._m(0),_c('div',{staticClass:"is-flex is-center mt-20 is-medium-16-500-19 has-primary-text"},[_vm._v(" "+_vm._s(_vm.localization("app-no-thermal", "No Thermal"))+" ")]),_c('div',{staticClass:"is-flex is-center mt-10 is-regular-12-00-18 has-primary-text has-opacity-8"},[_vm._v(" "+_vm._s(_vm.localization("app-no-result-found", "No result found"))+" ")])])],1):_vm._e()]):_vm._e(),(_vm.terra.length)?_c('div',{staticClass:"card mt-20"},[_c('header',{staticClass:"card-header py-5"},[_c('a',{staticClass:"card-header-icon",on:{"click":function($event){_vm.terra_active = !_vm.terra_active}}},[_c('sh-icon',{staticClass:"is-20x20",attrs:{"name":_vm.terra_active ? 'expand-active' : 'collapse-active'}})],1),_c('p',{staticClass:"card-header-title no-padding-left is-capitalized is-medium-18-500-22"},[_vm._v(" "+_vm._s(_vm.localization("app-terra", "Terra"))+" ("+_vm._s(_vm.filtered_terra.length)+") ")]),_c('div',{staticClass:"is-pointer is-pulled-right is-flex align-center pr-10 is-medium-14-500-17",on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
                name: 'terra-list',
                query: { asset: _vm.asset.uid, name: _vm.asset.name }
              })}}},[_c('sh-icon',{staticClass:"is-18x18 mr-5",attrs:{"name":'terra',"file":"helper_sprite"}}),_vm._v(_vm._s(_vm.localization("app-terra", "Terra"))+" ")],1)]),(_vm.terra_active)?_c('div',{staticClass:"card-content no-padding-top mt-5"},[_c('searchbar',{on:{"search":function($event){_vm.search_terra = $event}}}),_c('hr'),(_vm.filtered_terra.length)?_c('div',{staticClass:"mt-5 choose-apps--terra has-slimscroll-xs"},_vm._l((_vm.filtered_terra),function(item){return _c('div',{key:item.uid,staticClass:"columns choose-apps--terra--item is-marginless list-card  py-5 is-flex align-center sh-list__item"},[_c('div',{staticClass:"column is-11"},[_c('router-link',{attrs:{"to":{ name: 'terra-viewer', params: { id: item.uid } }},nativeOn:{"click":function($event){return _vm.$emit('close')}}},[_c('info-block',{attrs:{"info":{
                      name: item.name,
                      name_class:
                        'is-semiBold-16-600-22 name-highlights-on-hover',
                      bottom: item.description
                        ? item.description
                        : _vm.localization(
                            'app-no-description-found',
                            '*No description found'
                          ),
                      bottom_class:
                        'is-regular-14-500-17 has-opacity-7 has-text-theme-black',
                      type: 'projectManagement'
                    },"truncate_value":35,"hideImage":true}})],1)],1),_c('div',{staticClass:"column is-flex is-end is-1 actions"},[_c('router-link',{staticClass:"is-pointer pr-10",attrs:{"to":{ name: 'terra-details', params: { id: item.uid } }}},[_c('div',{staticClass:"is-flex align-center is-medium-14-500-17"},[_vm._v(" "+_vm._s(_vm.localization("app-dashboard", "Dashboard"))+" "),_c('sh-icon',{staticClass:" ml-5 is-15x15",attrs:{"name":'group-right-arrow'}})],1)])],1)])}),0):_c('div',{staticClass:"m-no-terra"},[_vm._m(1),_c('div',{staticClass:"is-flex is-center mt-20 is-medium-16-500-19 has-primary-text"},[_vm._v(" "+_vm._s(_vm.localization("app-no-terra", "No Terra"))+" ")]),_c('div',{staticClass:"is-flex is-center mt-10 is-regular-12-00-18 has-primary-text has-opacity-8"},[_vm._v(" "+_vm._s(_vm.localization("app-no-result-found", "No result found"))+" ")])])],1):_vm._e()]):_vm._e()]),_c('div',{staticClass:"card-content mt-30"},[_c('p',{staticClass:"is-medium-14-500-17 mb-15"},[_vm._v(" "+_vm._s(_vm.localization("app-applications", "Applications"))+" ")]),_c('div',{staticClass:"is-flex align-center",staticStyle:{"flex-wrap":"wrap"}},_vm._l((_vm.apps),function(item){return _c('div',{key:item.id},[(item.split_permissions)?_c('div',{staticClass:"choose-apps--chip is-pointer px-10 py-5 mr-10 is-inline-flex align-center",on:{"click":function($event){item.id == 7 ? _vm.Strategix4() : (_vm.goto_app(item), _vm.$emit('close'))}}},[_c('span',[_c('sh-icon',{staticClass:"is-18x18",attrs:{"name":item.sprite,"file":"helper_sprite"}})],1),_c('p',{staticClass:"is-medium-14-500-17 ml-5"},[_vm._v(" "+_vm._s(_vm.localization(("app-" + (item.name.toLowerCase())), item.name))+" ")])]):_vm._e()])}),0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-no-terra--img"},[_c('img',{attrs:{"src":require("@/assets/illustrations/m-no-thermal-illustration.svg"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-no-terra--img"},[_c('img',{attrs:{"src":require("@/assets/illustrations/terra-illustration.svg"),"alt":""}})])}]

export { render, staticRenderFns }