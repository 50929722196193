var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mission-list"},[_c('router-view'),_c('div',{staticClass:"list-top-section"},[_c('div',{staticClass:"list-top-section--search"},[_c('searchbar',{on:{"search":function($event){return _vm.pageChangeHandle(1, $event)}}})],1),_c('div',{staticClass:"list-top-section--create",staticStyle:{"display":"flex","align-items":"center"}},[(_vm.$route.name === 'group-details-missions')?_c('router-link',{staticClass:"mission-list__location",attrs:{"to":{ name: 'missions-viewer', params: { id: _vm.$route.params.id } },"tag":"div"}},[_c('img',{attrs:{"src":require("@/assets/icons/details/location.svg")}})]):_vm._e(),(_vm.access('missions', 'can_modify_missions'))?_c('a',{on:{"click":function($event){_vm.open_form(_vm.MissionFormComponent, {
            header: _vm.localization(
              'app-create-mission-for',
              'Create Mission for'
            ),
            group_id: _vm.$route.params.id
          })}}},[_c('button',{staticClass:"sh-button sh-button--create"},[_vm._v(" "+_vm._s(_vm.localization("app-new-mission", "New Mission"))+" ")])]):_c('a',{on:{"click":function($event){return _vm.no_access_popup('missions', 'can_modify_missions')}}},[_c('button',{staticClass:"sh-button sh-button--create is-not-allowed disabled-link"},[_vm._v(" "+_vm._s(_vm.localization("app-new-mission", "New Mission"))+" ")])])],1)]),(_vm.missions && _vm.missions.length)?_c('div',_vm._l((_vm.missions),function(item){return _c('div',{key:item.id},[(
          (item.readUsers && item.readUsers.length) ||
            (item.writeUsers && item.writeUsers.length)
        )?_c('div',{staticClass:"sh-list"},[_c('div',{staticClass:"sh-list__item"},[_c('mission-list-card',{attrs:{"missionGroup":item,"group":_vm.groupDict[item.groupUid],"owner":_vm.$store.getters.state_users[item.owner.uid],"users":item.readUsers.concat( item.writeUsers).map(
                function (user) { return _vm.$store.getters.state_users[user.uid]; }
              )},on:{"edit":function($event){return _vm.open_form(
                _vm.MissionFormComponent,
                { header: 'Edit Mission for' },
                $event
              )}}})],1)]):_vm._e()])}),0):_c('div',[(_vm.isLoading)?_c('div',[_c('list-skeleton',{attrs:{"options":{
          row_count: 5,
          col_count: 4,
          name: 2,
          team: 1,
          user: 0,
          tags: 1,
          status: 0
        }}})],1):_c('no-results',[_c('img',{attrs:{"slot":"image","src":require("@/assets/illustrations/missions-illustration.svg"),"alt":""},slot:"image"}),_c('div',{attrs:{"slot":"button"},slot:"button"},[_c('a',{on:{"click":function($event){return _vm.open_form(_vm.MissionFormComponent, {
              header: 'Create Mission for',
              group_id: _vm.$route.params.id
            })}}},[_c('div',{staticClass:"sh-button sh-button--create"},[_vm._v(" "+_vm._s(_vm.localization("app-create-new-mission", "Create New Mission"))+" ")])])])])],1),_c('pagination',{staticClass:"articles-list__pagination",attrs:{"default_page_size":_vm.default_page_size,"current-page":_vm.currentPage,"page-count":_vm.pageCount,"visiblePagesCount":_vm.visiblePagesCount,"totalRecords":_vm.totalRecords},on:{"nextPage":function($event){return _vm.pageChangeHandle('next')},"previousPage":function($event){return _vm.pageChangeHandle('previous')},"loadPage":_vm.pageChangeHandle}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }