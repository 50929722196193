var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('article',{staticClass:"media is-flex align-center",class:[_vm.custom_class]},[_c('figure',{class:[{ 'is-hidden': _vm.hideImage }]},[(_vm.info.image)?_c('p',{staticClass:"mr-10"},[_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.info.name, html: false }),expression:"{ content: info.name, html: false }"}],staticClass:"tiny-user-avatar is-30x30",attrs:{"src":_vm.info.image}})]):(_vm.info.properties)?_c('p',{staticClass:"is-30x30 mr-10",class:[_vm.info.custom_icon_class]},[(_vm.info.properties.icon)?_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.info.name, html: false }),expression:"{ content: info.name, html: false }"}],staticClass:"tiny-user-avatar is-30x30",attrs:{"src":_vm.info.properties.icon}}):_c('span',{staticClass:"tiny-user-avatar is-medium-14-500-17 is-30x30",style:(("background-color: " + (_vm.info.properties.color
                ? _vm.info.properties.color
                : _vm.hashed_color(
                    _vm.info.name,
                    _vm.info.type ? _vm.info.type : 'projectManagement'
                  ))))},[_vm._v(_vm._s(_vm.info.name.charAt(0)))])]):_c('span',{staticClass:"tiny-user-avatar is-medium-14-500-17 is-30x30 mr-10",style:(("background-color: " + (_vm.hashed_color(
            _vm.info.name,
            _vm.info.type ? _vm.info.type : 'projectManagement'
          ))))},[_vm._v(_vm._s(_vm.info.name.charAt(0)))])]),_c('div',{staticClass:"media-content",class:[_vm.info.custom_content_class]},[_c('div',{staticClass:"content"},[_c('p',[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content:
                _vm.show_tooltip && _vm.info.name.length > (_vm.truncate_value || 15)
                  ? _vm.info.name
                  : '',
              html: false
            }),expression:"{\n              content:\n                show_tooltip && info.name.length > (truncate_value || 15)\n                  ? info.name\n                  : '',\n              html: false\n            }"}],staticClass:"is-block",class:[_vm.info.name_class ? _vm.info.name_class : '']},[_vm._v(_vm._s(_vm._f("truncate")(_vm.info.name,_vm.truncate_value || 15)))]),_c('span',{class:[_vm.info.subtext_class ? _vm.info.subtext_class : '']},[_vm._v(_vm._s(_vm.info.subtext))])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }