var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('skeleton-loading',[_c('row',{attrs:{"gutter":{ top: '15px', bottom: '10px' }}},[_c('column',{attrs:{"span":20}},[_c('square-skeleton',{attrs:{"boxProperties":{
            top: '5px',
            width: '100%',
            height: '15px'
          }}}),_c('square-skeleton',{attrs:{"boxProperties":{
            top: '5px',
            width: '50%',
            height: '15px'
          }}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }