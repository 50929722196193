var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"invite",on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.add_user($event)}}},[_c('div',{staticClass:"invite__header mt-10 columns"},[_c('div',{staticClass:"is-capitalized is-medium-16-500-19 column"},[_vm._v(" "+_vm._s(_vm.localization("app-email-address", "Email Address"))+" ")]),_c('div',{staticClass:"is-capitalized is-medium-16-500-19 column"},[_vm._v(" "+_vm._s(_vm.localization("app-full-name", "Full name"))+" ")]),_c('i',{staticClass:"is-vhidden fa fa-times column is-1 fa-2x has-text-grey-light is-pointer",attrs:{"aria-hidden":"true"}})]),_vm._l((_vm.users),function(user,index){return _c('div',{key:index,staticClass:"mb-5 columns"},[_c('div',{staticClass:"is-medium-16-500-19 column "},[_vm._v(_vm._s(user.email))]),_c('div',{staticClass:"is-medium-16-500-19 column "},[_vm._v(_vm._s(user.name || "None"))]),_c('i',{staticClass:"fa fa-times column is-1 fa-2x has-text-grey-light is-pointer",attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.users.splice(index, 1)}}})])}),(_vm.show_form || !_vm.users.length)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return _vm.add_user(); }),expression:"() => add_user()"}],staticClass:"columns mb-20 align-center"},[_c('div',{staticClass:"column"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"},{name:"focus",rawName:"v-focus"}],staticClass:"sh-form__content__input mr-10",class:{
          'sh-form__content__input--error': _vm.form.email && !_vm.is_valid_email
        },attrs:{"type":"email","placeholder":_vm.localization(
            'app-type-email-and-enter',
            'Type email address and press enter'
          )},domProps:{"value":(_vm.form.email)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.add_user($event)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),(_vm.form.email && _vm.form.email.length && !_vm.is_valid_email)?_c('p',{staticClass:"is-absolute"},[_vm._v(" Please enter valid email address ")]):_vm._e()]),_c('div',{staticClass:"column"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"sh-form__content__input",class:{
          'sh-form__content__input--error':
            _vm.form.name && _vm.form.name.length && !_vm.is_valid_full_name
        },attrs:{"type":"text","placeholder":_vm.localization('app-type-name', 'Firstname Lastname')},domProps:{"value":(_vm.form.name)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.add_user()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}}),(_vm.form.name && _vm.form.name.length && !_vm.is_valid_full_name)?_c('p',{staticClass:"is-absolute"},[_vm._v(" Please enter full name "),_c('i',[_vm._v("(John Smith)")])]):_vm._e()]),_c('i',{staticClass:"fa fa-check column is-1 fa-2x  is-pointer is-paddingless",class:[
        _vm.is_valid_email && _vm.is_valid_full_name
          ? 'has-text-success'
          : 'is-disabled has-text-grey-light'
      ],attrs:{"aria-hidden":"true"},on:{"click":_vm.add_user}})]):_vm._e(),_c('div',{staticClass:"mt-40"},[_c('p',{staticClass:"is-medium-16-500-19"},[_vm._v(_vm._s(_vm.localization("app-note", "Note")))]),_c('p',{staticClass:"is-regular-16-00-19 mt-20"},[_vm._v(" "+_vm._s(_vm.localization( "app-text-user-invite-info", "Users will receive an email to signup your organization." ))+" ")])]),_c('div',{staticClass:"invite-container__footer"},[_c('div',{staticClass:"buttons"},[_c('div',{staticClass:"sh-button is-pulled-right is-semiBold-14-600-17 mr-10",staticStyle:{"background-color":"white","color":"#171e48","opacity":"0.6"},on:{"click":function($event){return _vm.$emit('back')}}},[_vm._v(" "+_vm._s(_vm.localization("app-back", "Back"))+" ")]),(_vm.awaitingResponse)?_c('div',{staticClass:"sh-button is-pulled-right"},[_c('i',{staticClass:"fa fa-spinner fa-spin",attrs:{"aria-hidden":"true"}})]):_c('div',{staticClass:"is-pulled-right sh-button is-semiBold-14-600-17",class:[!_vm.users.length ? 'is-disabled' : ''],on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.localization("app-invite-new-users", "Invite New Users"))+" ")])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }