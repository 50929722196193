var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"thermv2-map-container"},[_c('div',{staticClass:"thermv2-map-container__map",attrs:{"id":"therm-v2-map"}}),_c('div',{staticClass:"thermv2-map-container__map__topbar has-text-centered is-flex is-center align-center"},[(_vm.container && _vm.container.length)?_c('p',{staticClass:"is-medium-14-500-17"},[_vm._v(" "+_vm._s(_vm.container[0].viewName)+" ")]):_vm._e()]),_c('div',{staticClass:"thermv2-map-container__map__layer-select",style:(_vm.toolbar_position)},[_vm._l((_vm.supported_layers),function(layer){return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.localization(("app-" + layer), layer),
        placement: 'left-center',
        classes: ['inverted-tooltip']
      }),expression:"{\n        content: localization(`app-${layer}`, layer),\n        placement: 'left-center',\n        classes: ['inverted-tooltip']\n      }"}],key:layer,staticClass:"is-pointer  is-semiBold-14-600-17 image my-10 mr-20 has-br-3",class:[
        _vm.active_layer_types.includes(layer.toLowerCase())
          ? 'has-blue-border'
          : 'has-white-border'
      ],on:{"click":function($event){_vm.SET_ACTIVE_LAYER_TYPES(layer.toLowerCase())}}},[_c('img',{staticClass:"is-28x28",attrs:{"src":require(("@/assets/icons/thermv2/" + (layer.toLowerCase()) + ".svg")),"alt":""}}),(_vm.active_layer_types.includes(layer.toLowerCase()))?_c('div',{staticClass:"layer-check icon"},[_c('i',{staticClass:"fa fa-check has-text-white",attrs:{"aria-hidden":"true"}})]):_vm._e()])}),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.localization('app-add-task', 'Add task'),
        html: false,
        placement: 'left-center',
        classes: ['inverted-tooltip']
      }),expression:"{\n        content: localization('app-add-task', 'Add task'),\n        html: false,\n        placement: 'left-center',\n        classes: ['inverted-tooltip']\n      }"}],staticClass:"map-marker is-pointer",class:{ 'is-disabled': !_vm.active_projects.length },on:{"click":function($event){return _vm.toggle_marker()}}},[(_vm.map_marker_active)?_c('img',{attrs:{"src":require("@/assets/icons/add-task-selected.png"),"alt":""}}):_c('img',{attrs:{"src":require("@/assets/icons/add-task.png"),"alt":""}})])],2),_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      content: _vm.localization('app-compare', 'Compare'),
      classes: ['inverted-tooltip']
    }),expression:"{\n      content: localization('app-compare', 'Compare'),\n      classes: ['inverted-tooltip']\n    }"}],staticClass:"thermv2-map-container__map__compare-projects is-pointer",style:(_vm.toolbar_position),attrs:{"tag":"div","to":{
      name: 'therm-v2-compare'
    }}},[_c('sh-icon',{staticClass:"is-48x48 invert_colors",attrs:{"name":"compare-projects","file":"thermv2_sprite"}})],1),(_vm.element && _vm.open_ticket_create)?_c('create-ticket',{staticClass:"thermv2-ticket-create has-background-white invert_colors has-opacity-9",staticStyle:{"position":"absolute","left":"30rem","z-index":"99","top":"20rem","width":"45rem","border-radius":"1rem"},attrs:{"open_on_create":true,"element":Object.assign({}, _vm.element, {centroid: _vm.centroid}),"project_details":_vm.active_project_details,"viewer_create_ticket":true,"viewer_ticket":false},on:{"close":function($event){_vm.open_ticket_create = false;
      _vm.remover_marker();},"ticket-created":function($event){_vm.open_ticket_create = false;
      _vm.remover_marker();}}}):_vm._e(),(_vm.map)?_c('Cluster'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }