var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-card"},[_c('router-view'),_c('div',{staticClass:"list-top-section is-paddingless"},[_c('div',{staticClass:"list-top-section--search"},[_c('searchbar',{on:{"search":function($event){return _vm.pageChangeHandle(1, $event)}}})],1),_c('div',{staticClass:"list-top-section--create"},[_c('div',{staticClass:"sh-button sh-button--inverted",on:{"click":_vm.assign_containers}},[_vm._v(" "+_vm._s(_vm.localization("app-assign-containers", "Assign Containers"))+" ")])])]),(_vm.data && _vm.data.length)?_c('div',[_c('list-header'),_c('div',{staticClass:"sh-list"},_vm._l((_vm.data),function(item){return _c('div',{key:item.uid,staticClass:"sh-list__item"},[_c('div',{staticClass:"columns is-flex align-center"},[_c('div',{staticClass:"column is-3"},[_c('router-link',{staticClass:"has-text-dark",attrs:{"to":{ name: 'asset-details', params: { id: item.uid } }}},[_c('main-details-media',{attrs:{"info":{
                  name: item.name,
                  name_class: 'is-medium-16-500-19 has-primary-text',
                  subtext: item.group_counts + ' groups',
                  subtext_class:
                    'is-medium-14-500-17 has-primary-text is-capitalized has-opacity-7',
                  properties: item.properties,
                  truncate_value: 1
                },"hideImage":false}})],1)],1),_c('div',{staticClass:"column is-3"},[_c('primary-tag',{attrs:{"tags":item.asset ? [item.asset] : null,"type":'asset',"truncate_length":6,"noTopMargin":true}})],1),_c('div',{staticClass:"column is-3"},[(item.app_types && item.app_types.length)?_c('app-type-tag',{attrs:{"apps":item.app_types,"count":3,"item_id":item.uid}}):_c('span',{staticClass:"is-flex align-center"},[_c('img',{staticClass:"mr-5 has-opacity-6",attrs:{"src":require("@/assets/icons/not-assigned/application.svg")}}),_c('span',{staticClass:"is-regular-14-00-17 has-opacity-6"},[_vm._v(_vm._s(_vm.localization("app-no-apps-assigned", "*No apps assigned")))])])],1),_c('div',{staticClass:"column is-3 is-flex align-center is-between"},[_c('div',{staticClass:"is-flex"},[_c('div',{staticClass:"mr-10 is-flex is-vcenter"},[_c('span',{staticClass:"sh-access-tag",class:{
                    'sh-access-tag--read':
                      item.access === 'read' || item.access === 'write'
                  }},[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}})]),_c('span',{staticClass:"ml-5 is-regular-12-00-14 has-text-theme-black has-opacity-7"},[_vm._v(_vm._s(_vm.localization("app-read", "Read")))])]),_c('div',{staticClass:"is-flex is-vcenter"},[_c('span',{staticClass:"sh-access-tag",class:{ 'sh-access-tag--write': item.access === 'write' }},[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}})]),_c('span',{staticClass:"ml-5 is-regular-12-00-14 has-text-theme-black has-opacity-7"},[_vm._v(_vm._s(_vm.localization("app-write", "Write")))])])]),(!_vm.$store.getters.is_org_user)?_c('div',{staticClass:"user--containerlist--drop"},[_c('sh-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                  content: _vm.localization(
                    'app-remove-container',
                    'Remove container'
                  )
                }),expression:"{\n                  content: localization(\n                    'app-remove-container',\n                    'Remove container'\n                  )\n                }"}],staticClass:"is-16x16 mr-10 is-pointer",attrs:{"name":'close',"file":'helper_sprite'},nativeOn:{"click":function($event){return _vm.drop_container(item)}}})],1):_vm._e()])]),_c('transfer-owner',{ref:'containerList' + item.uid,refInFor:true,attrs:{"name":item.name,"service":_vm.containerService,"id":item.uid},on:{"update_user":function($event){return _vm.update_owner(item, $event)}}})],1)}),0)],1):_c('div',[(_vm.isLoading)?_c('div',[_c('list-header'),_c('list-skeleton')],1):_c('no-results',[_c('img',{attrs:{"slot":"image","src":require("@/assets/illustrations/containers-illustration.svg"),"alt":""},slot:"image"}),_c('div',{attrs:{"slot":"button"},slot:"button"},[(_vm.$route.name === 'container-list')?_c('router-link',{attrs:{"to":{ name: 'container-create' }}},[_c('div',{staticClass:"sh-button sh-button--create"},[_vm._v(" "+_vm._s(_vm.localization("app-create-new-container", "Create New Container"))+" ")])]):_vm._e()],1)])],1),_c('div',[_c('pagination',{staticClass:"articles-list__pagination",attrs:{"default_page_size":_vm.default_page_size,"current-page":_vm.currentPage,"page-count":_vm.pageCount,"visiblePagesCount":_vm.visiblePagesCount,"totalRecords":_vm.totalRecords},on:{"nextPage":function($event){return _vm.pageChangeHandle('next')},"previousPage":function($event){return _vm.pageChangeHandle('previous')},"loadPage":_vm.pageChangeHandle}})],1),(_vm.showDeleteModal)?_c('delete-modal',{attrs:{"resourceId":_vm.currentContainer.uid,"resourceName":_vm.currentContainer.name,"resourceType":'container',"resourceService":_vm.containerService},on:{"closed":function($event){_vm.showDeleteModal = false},"deleted":function($event){_vm.show_success('Resource successfully deleted');
      _vm.getData();}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }