var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('skeleton-loading',_vm._l((4),function(item){return _c('row',{key:item,staticClass:"activities-skeleton",attrs:{"gutter":{ top: '40px' }}},[_c('column',{attrs:{"span":24}},[_c('row',[_c('column',{attrs:{"span":8}},[_c('square-skeleton',{attrs:{"boxProperties":{
                height: '10px',
                left: '10px'
              }}})],1)],1),_vm._l((3),function(item){return _c('row',{key:item,attrs:{"gutter":{ top: '40px' }}},[_c('column',{attrs:{"span":1}}),_c('column',{attrs:{"span":1}},[_c('circle-skeleton')],1),_c('column',{attrs:{"span":22,"gutter":10}},[_c('row',[_c('column',{attrs:{"span":6}},[_c('square-skeleton',{attrs:{"boxProperties":{
                    top: '5px',
                    width: '100%',
                    height: '10px'
                  }}})],1),_c('column',{attrs:{"span":12}}),_c('column',{attrs:{"span":6}},[_c('square-skeleton',{attrs:{"boxProperties":{
                    top: '5px',
                    width: '100%',
                    height: '10px'
                  }}})],1)],1),_c('square-skeleton',{attrs:{"boxProperties":{
                width: '30%',
                height: '12px',
                top: '10px'
              }}})],1)],1)})],2)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }