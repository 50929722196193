import { render, staticRenderFns } from "./team-list-header.vue?vue&type=template&id=d96de9aa&scoped=true&"
import script from "./team-list-header.vue?vue&type=script&lang=js&"
export * from "./team-list-header.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d96de9aa",
  null
  
)

export default component.exports