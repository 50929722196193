var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project-list",class:[
    _vm.$route.name == 'user-details-groups' ? null : 'project-list-margin'
  ]},[_c('div',{staticClass:"project-list__actions level",staticStyle:{"height":"4.4rem"}},[_c('div',{staticClass:"is-1 level-left"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.all_projects),expression:"all_projects"}],staticClass:"sh-custom-checkbox is-thinner fill",attrs:{"type":"checkbox","name":"project","id":"select"},domProps:{"checked":Array.isArray(_vm.all_projects)?_vm._i(_vm.all_projects,null)>-1:(_vm.all_projects)},on:{"input":function($event){_vm.all_projects
            ? (_vm.selected = [])
            : (_vm.selected = _vm.data.map(function (item) { return item.uid; }))},"change":function($event){var $$a=_vm.all_projects,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.all_projects=$$a.concat([$$v]))}else{$$i>-1&&(_vm.all_projects=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.all_projects=$$c}}}}),_c('label',{staticClass:"is-medium-16-500-19 ml-10 no-whitespace-wrap",attrs:{"for":"select"}},[_vm._v(" "+_vm._s(_vm.localization("app-select-all", "Select all")))])]),(_vm.selected.length > 1)?_c('project-actions',{staticClass:"level-right",attrs:{"selected":_vm.selected,"data":_vm.data,"group_id":_vm.group_id},on:{"update_selection":_vm.select_all,"delete_selected":_vm.delete_selected,"append_duplicated_projects":_vm.add_duplicated_projects}}):_c('div',{staticClass:"level-right"},[(_vm.access('core', 'create_projects'))?_c('a',{on:{"click":function($event){_vm.open_form(_vm.ProjectFormComponent, {
            header: _vm.localization(
              'app-create-project-for',
              'Create Project for'
            ),
            group_id: _vm.group_id
          })}}},[_c('button',{staticClass:"sh-button sh-button--create sh-button--inverted"},[_vm._v(" "+_vm._s(_vm.localization("app-new-project", "New Project"))+" ")])]):_c('a',{on:{"click":function($event){return _vm.no_access_popup('core', 'create_projects')}}},[_c('button',{staticClass:"sh-button sh-button--create disabled-link sh-button--inverted"},[_vm._v(" "+_vm._s(_vm.localization("app-new-project", "New Project"))+" ")])])])],1),_c('div',{staticClass:"project-divider is-thicker"}),(_vm.data && _vm.data.length)?_c('div',[_c('div',{staticClass:"no-box-shadow no-padding-bottom mb-20 mt-20"},[_c('div',[_c('div',{staticClass:"columns is-flex is-vcenter"},[_c('div',{staticClass:"column is-4"},[_c('h4',{staticClass:"pl-40 is-marginless is-medium-16-500-19"},[_vm._v(" "+_vm._s(_vm.localization("app-project-name", "Project Name"))+" ")])]),_c('div',{staticClass:"column",class:[_vm.$route.name == 'group-list' ? 'is-3' : 'is-4']},[_c('h4',{staticClass:"is-marginless is-medium-16-500-19"},[_vm._v(" "+_vm._s(_vm.localization("app-date", "Date"))+" ")])]),(_vm.$route.name == 'group-list')?_c('div',{staticClass:"column is-3"},[_c('h4',{staticClass:"is-marginless is-medium-16-500-19"},[_vm._v(" "+_vm._s(_vm.localization("app-mission", "Mission"))+" ")])]):_vm._e(),_c('div',{staticClass:"column is-2"},[_c('h4',{staticClass:"is-marginless is-medium-16-500-19"},[_vm._v(" "+_vm._s(_vm.localization("app-processing", "Processing"))+" ")])])])]),_vm._l((_vm.data),function(item,i){return _c('div',{key:item.uid,staticClass:"project-list__item"},[_c('div',{on:{"mouseenter":function($event){_vm.hover = item.uid},"mouseleave":function($event){_vm.hover = -1}}},[(item)?_c('project-list-item',{staticClass:"py-15",attrs:{"item":item,"hover":_vm.hover,"selected":_vm.selected},on:{"edit":function($event){return _vm.open_form(
                _vm.ProjectFormComponent,
                {
                  header: 'Edit Project for',
                  subheader: item.group.name,
                  group_id: _vm.group_id
                },
                item
              )},"add_to_selection":_vm.update_selection}}):_vm._e(),(i != _vm.data.length - 1)?_c('div',{staticClass:"project-divider is-marginless"}):_vm._e()],1)])})],2)]):_c('div',[(_vm.isLoading)?_c('div',[_c('list-skeleton',{attrs:{"options":{
          row_count: 5,
          col_count: 4,
          text: 4
        }}})],1):_c('no-results',[_c('img',{attrs:{"slot":"image","src":require("@/assets/illustrations/groups-illustration.svg"),"alt":""},slot:"image"}),(!_vm.show_deleted)?_c('div',{attrs:{"slot":"button"},slot:"button"},[_c('a',{on:{"click":function($event){_vm.open_form(_vm.ProjectFormComponent, {
              header: _vm.localization(
                'app-create-project-for',
                'Create Project for'
              ),
              group_id: _vm.group_id
            })}}},[_c('div',{staticClass:"sh-button sh-button--create"},[_vm._v(" "+_vm._s(_vm.localization("app-create-new-project", "Create New Project"))+" ")])])]):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }