var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-card__body has-slimscroll-xs"},_vm._l((_vm.sort(_vm.group.projects)),function(project){return _c('div',{key:project.uid,staticClass:"list-card__body--item is-bottom-border"},[_c('div',{staticClass:"list-card__body--item-text is-pointer is-medium-15-500-18",class:{ 'terra-active-project': _vm.active_project.uid === project.uid },on:{"click":function($event){_vm.$emit('select-active-project', project);
        _vm.$eventHub.$emit('terra_active_project', project);}}},[_vm._v(" "+_vm._s(project.name)+" ")]),_c('div',{staticClass:"list-card__body--item-action is-pointer",on:{"click":function($event){return _vm.download_report(
          project.reports &&
            project.reports.report &&
            project.reports.report.url
        )},"mouseenter":function($event){_vm.hover = project.uid},"mouseleave":function($event){_vm.hover = -1}}},[(
          _vm.hover == project.uid &&
            project.reports &&
            project.reports.report &&
            project.reports.report.url
        )?_c('sh-icon',{staticClass:"is-32x32",attrs:{"name":'download-fill',"file":'helper_sprite'}}):_c('sh-icon',{staticClass:"is-32x32 is-not-allowed",attrs:{"name":'download2',"file":'helper_sprite'}})],1)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }